import { createSlice, current } from "@reduxjs/toolkit";
import { fetchVideoSummaryThunk, fetchVideosByIdsThunk } from "../actions/videoAction";

const initialState = {
	summaryLoading: false,
	error: null,
	videoData: [],
	videoLoading: false,
	videoError: null,
};

const videoSlice = createSlice({
	name: "video",
	initialState,
	reducers: {
		addVideo: (state, action) => {
			let currentVideo = current(state);
			state.videoData = [...currentVideo.videoData, action.payload];
		},
		clearVideo: (state) => {
			state.videoData = [];
		},
		deleteVideo: (state, action) => {
			let currentVideo = current(state);
			state.videoData = currentVideo.videoData.filter((video) => video.video_id !== action.payload?.video_id);
		},
		updateVideo: (state, action) => {
			let currentState = current(state);
			state.videoData = currentState.videoData.map((video) => {
				if (video.video_id === action.payload.video_id) {
					return {
						...video,
						...action.payload,
					};
				}
				return video;
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchVideoSummaryThunk.pending, (state) => {
				state.summaryLoading = true;
				state.error = null;
			})
			.addCase(fetchVideoSummaryThunk.fulfilled, (state, action) => {
				state.summaryLoading = false;
			})
			.addCase(fetchVideoSummaryThunk.rejected, (state, action) => {
				state.summaryLoading = false;
				state.error = action.payload;
			});

		builder
			.addCase(fetchVideosByIdsThunk.pending, (state) => {
				state.videoLoading = true;
				state.videoError = null;
			})
			.addCase(fetchVideosByIdsThunk.fulfilled, (state, action) => {
				state.videoLoading = false;
				state.videoData = action.payload;
				state.videoError = null;
			})
			.addCase(fetchVideosByIdsThunk.rejected, (state, action) => {
				state.videoLoading = false;
				state.videoError = action.payload;
			});
	},
});

export default videoSlice.reducer;
export const { addVideo, clearVideo, deleteVideo, updateVideo } = videoSlice.actions;
