import React, { useEffect, useState } from "react";
import { Box, Button, Tab, TabList, Tabs, Input, Textarea, Modal, ModalDialog, ModalClose, Typography, ListItem, List } from "@mui/joy";
import { Link } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteBlobThunk, fetchBlobsThunk, uploadFileThunk } from "../../Store/actions/blobStorageAction";
import WithAuth from "../utils/WithAuth";
// import { deleteBlobThunk, fetchBlobsThunk, uploadFileThunk } from "../../Store/slices/blobStorage";

const TopicCreator = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState("details");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [files, setFiles] = useState([]);
	const [selectedFile, setSelectedFile] = useState("");
	const { isFromAI } = useParams();
	const navigate = useNavigate();
	const { loading, referenceUrls, uploadLoading } = useSelector((state) => state.blobStorage);
	const accountName = process.env.REACT_APP_ACCOUNT_NAME;
	const sasToken = process.env.REACT_APP_SAS_TOKEN;
	const containerName = process.env.REACT_APP_CONTAINER_NAME;

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const handleFileUpload = (event) => {
		const selectedFiles = Array.from(event.target.files);
		setFiles(selectedFiles);
	};

	const DeleteHandler = (fileName) => {
		setSelectedFile(fileName);
		handleDelete(fileName);
	};

	useEffect(() => {
		if (isFromAI) {
			setActiveTab("tasks");
		}
		fetchBlobs();
	}, []);

	const fetchBlobs = () => {
		if (!accountName || !sasToken || !containerName) {
			alert("Please make sure you have set the Azure Storage credentials in the .env file");
			return;
		}
		dispatch(fetchBlobsThunk());
	};

	const handleDelete = async (blobName) => {
		if (!accountName || !sasToken || !containerName) {
			alert("Please make sure you have set the Azure Storage credentials in the .env file");
			return;
		}
		dispatch(deleteBlobThunk(blobName));
	};

	//upload pdf
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (files.length > 0) {
			dispatch(uploadFileThunk(files));
			setFiles([]);
		}
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 800, margin: "auto", p: 2 }}>
			<Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
				<Typography level='h6'>Create New Topic</Typography>
				<Box>
					{!isFromAI && (
						<Button
							variant='outlined'
							color='neutral'
							sx={{ mr: 1 }}
						>
							Cancel
						</Button>
					)}
					<Button onClick={() => navigate("/course/addcourse/2")}>{isFromAI ? "Submit" : "Create"}</Button>
				</Box>
			</Box>

			<Tabs
				value={activeTab}
				onChange={handleTabChange}
			>
				<TabList>
					<Tab
						value='details'
						disabled={isFromAI}
					>
						Topic Details
					</Tab>
					<Tab
						value='videos'
						disabled={isFromAI}
					>
						Videos
					</Tab>
					<Tab value='tasks'>Tasks</Tab>
					<Tab value='references'>References</Tab>
				</TabList>
			</Tabs>

			<Box
				sx={{
					p: 2,
					border: "1px solid",
					borderColor: "divider",
					height: "18rem",
					borderRadius: "sm",
					background: "white",
				}}
			>
				{activeTab === "details" && (
					<>
						<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Name</Typography>
						<Input
							placeholder='e.g. Compilers & Assemblers'
							label='Topic Name'
							sx={{ mb: 2 }}
						/>
						<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Description</Typography>
						<Textarea
							minRows={3}
							placeholder='Describe in a few lines what the topic is about.'
							label='Topic Description'
						/>
					</>
				)}

				{activeTab === "videos" && (
					<>
						<Typography sx={{ mb: 2 }}>Add Videos to the Topic for the students to watch and learn.</Typography>
						<Button onClick={() => setIsModalOpen(true)}>Add a New Video</Button>
					</>
				)}

				{activeTab === "tasks" && (
					<>
						<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, padding: "10px", borderRadius: "8px", bgcolor: "background.level1" }}>
							<Link
								component='button'
								level='body-sm'
								sx={{ mr: 2, fontSize: "14px" }}
							>
								+Create Task Manually
							</Link>
							<Link
								component='button'
								level='body-sm'
								sx={{ fontSize: "14px" }}
							>
								+Create Task with AI
							</Link>
						</Box>
						<Box
							sx={{
								p: 3,
								borderRadius: "sm",
								textAlign: "center",
							}}
						>
							<Typography
								level='body-md'
								color='neutral'
							>
								Add Tasks to the Topic for the students to do.
							</Typography>
						</Box>
					</>
				)}
				{activeTab === "references" && (
					<>
						<Box
							component='form'
							onSubmit={handleSubmit}
							sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
						>
							<Input
								type='file'
								multiple
								onChange={handleFileUpload}
								sx={{
									background: "#F4F4F4",
									padding: "0.5rem",
									flexGrow: 1,
								}}
							/>
							<Button
								type='submit'
								variant='contained'
								sx={{ color: "blue" }}
							>
								{uploadLoading ? "Uploading..." : "Upload"}
							</Button>
						</Box>
						{referenceUrls.length > 0 && (
							<List sx={{ mt: 2 }}>
								{referenceUrls.map((file, index) => (
									<ListItem
										key={index}
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<Link
											href={file.url}
											target='_blank'
											rel='noopener noreferrer'
										>
											{file.name}
										</Link>
										<Button
											variant='outlined'
											color='danger'
											size='sm'
											onClick={() => DeleteHandler(file.name)}
										>
											{selectedFile === file.name && loading ? "Deleting..." : "Delete"}
										</Button>
									</ListItem>
								))}
							</List>
						)}
					</>
				)}
			</Box>

			<Modal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h5'>Add New Video</Typography>
					<Input
						placeholder='e.g. Compiler - Assembler Differences'
						label='Video Title'
						sx={{ mt: 2 }}
					/>
					<Input
						placeholder='e.g. https://vimeo.com/123456789'
						label='Video URL'
						sx={{ mt: 2 }}
					/>
					<Textarea
						minRows={3}
						placeholder='You can write the summary or create with Intertekk AI'
						label='Video Summary'
						sx={{ mt: 2 }}
					/>
					<Button sx={{ mt: 2 }}>Create AI Summary from Video</Button>
					<Box sx={{ mt: 2 }}>
						<Typography>Points</Typography>
						<Box sx={{ display: "flex", gap: 1 }}>
							{[1, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
								>
									{point}
								</Button>
							))}
						</Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
						<Button
							variant='outlined'
							color='neutral'
							sx={{ mr: 1 }}
						>
							Cancel
						</Button>
						<Button>Add Video</Button>
					</Box>
				</ModalDialog>
			</Modal>
		</Box>
	);
};

export default WithAuth(TopicCreator);
