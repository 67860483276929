import { Modal, ModalClose, ModalDialog } from "@mui/joy";
import { Chip, Box, IconButton, Popper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddNewEvent from "../../event/create-event";
import AddIcon from "@mui/icons-material/Add";
import { fetchEventsByBatchIdThunk } from "../../../../Store/actions/batchEventAction";
import { useDispatch } from "react-redux";
import { LocalizationProvider, StaticDateTimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Loader from "../../../utils/Loader";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";

const BatchEvents = () => {
	const [open, setOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const [openNodeId, setOpenNodeId] = useState(null);

	const { batchDetails } = useSelector((state) => state.batch);
	const { events, loading: eventLoader, error } = useSelector((state) => state.batchEvent);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (batchDetails?.data?.batch_id) dispatch(fetchEventsByBatchIdThunk(batchDetails?.data?.batch_id));
	}, []);

	const handleDateChange = (date) => {
		// setDate(formattedDate);
	};

	const handleClick = (event, nodeId) => {
		setOpenNodeId((prev) => (prev === nodeId ? null : nodeId));
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleAccept = (event) => {};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenNodeId(null);
	};

	const meetingTypes = {
		GOOGLE_MEET: "G-meet",
		ZOOM: "Zoom",
		// Add other meeting types if necessary
	};

	const getMeetingTypeLabel = (type) => meetingTypes[type] || type;

	const renderNewEventModal = () => {
		return (
			<Modal
				aria-labelledby='modal-title'
				aria-describedby='modal-desc'
				open={open}
				onClose={() => setOpen(false)}
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose
						variant='plain'
						sx={{ m: 1 }}
					/>
					<Typography
						component='h2'
						id='modal-title'
						level='h4'
						textColor='inherit'
						fontWeight='lg'
						my={1}
					>
						Add New Event
					</Typography>
					<AddNewEvent
						batch_id={batchDetails?.data?.batch_id}
						setOpen={setOpen}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderDateTimePicker = (event) => {
		const dateValue = event?.start_time ? DateTime.fromISO(event?.start_time) : null;
		const isValidDate = dateValue && dateValue.isValid;
		return (
			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<Box sx={{ width: "10%", mr: 2 }}>
					<IconButton
						component='label'
						variant='contained'
						color='primary'
						aria-describedby={event?.event_id}
						sx={{
							borderRadius: "md",
						}}
						onClick={(event) => handleClick(event, event?.event_id)}
					>
						<Typography
							sx={{
								borderBottom: !isValidDate ? "2px solid black" : "none",
								width: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{isValidDate ? dateValue.toFormat("dd MMM") : ""}
						</Typography>
					</IconButton>

					<Popper
						id={event.event_id}
						open={openNodeId === event?.event_id}
						anchorEl={anchorEl}
						placement='bottom'
						sx={{ zIndex: 1000, border: "1px solid black" }}
					>
						<StaticDateTimePicker
							onChange={handleDateChange}
							onAccept={() => handleAccept(event)}
							onClose={handleClose}
							value={isValidDate ? dateValue : null}
							minDate={DateTime.fromISO(batchDetails?.data?.start_date)}
							maxDate={DateTime.fromISO(batchDetails?.data?.end_date)}
							sx={{ height: "20rem", overflowY: "auto", backgroundColor: "white" }}
						/>
					</Popper>
				</Box>
			</LocalizationProvider>
		);
	};

	const renderEventCard = (event) => {
		const getDuration = () => {
			const startTime = DateTime.fromISO(event?.start_time).setZone("Asia/Calcutta");
			const endTime = DateTime.fromISO(event?.end_time).setZone("Asia/Calcutta");

			const duration = endTime.diff(startTime, ["hours", "minutes"]).toObject();

			return `${duration.hours < 0 ? `${-duration?.hours}` : duration?.hours}hrs ${duration?.minutes ? `${duration.minutes.toFixed()}mins` : ""}`;
		};

		return (
			<Box sx={{ display: "flex", alignItems: "center", width: "100%", overflowX: "hidden", my: 2 }}>
				{renderDateTimePicker(event)}
				<Box sx={{ backgroundColor: "#f4f4f4", width: "95%", display: "flex", alignItems: "center", p: 1, borderRadius: "10px" }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", px: 3 }}>
						<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
							<Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>{event?.name}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
							<Typography
								sx={{ color: "GrayText" }}
								level='title-sm'
							>
								{event?.instructor_name}
							</Typography>
							<Tooltip title='Open meeting link'>
								<Link
									to={event?.meeting_url}
									target='_blank'
								>
									<Chip
										label={getMeetingTypeLabel(event?.meeting_type)}
										icon={<OpenInNewIcon sx={{ fontSize: "1rem" }} />}
										clickable
										variant='outlined'
										color='info'
										style={{ marginRight: 8, px: 5, width: "100px" }}
									/>
								</Link>
							</Tooltip>
							{event?.event_image && (
								<img
									src={event?.event_image}
									alt='event_img'
									style={{ width: "40px", height: "40px" }}
								/>
							)}

							<Typography
								sx={{ color: "GrayText" }}
								level='title-sm'
							>
								{getDuration()}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderEvents = () => {
		if (eventLoader) return <Loader />;
		else if (events?.length > 0) {
			return events?.map((event) => renderEventCard(event));
		} else if (error) {
			dispatch(showToast({ toastMessage: error, toastType: ToastType.ERROR }));
			setTimeout(() => navigate("/batches"), 2000);
			return <div>{error}</div>;
		} else {
			return <div>No events found</div>;
		}
	};

	return (
		<Box>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #888", px: 1 }}>
				<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Batch Events</Typography>
				<Typography
					sx={{ display: "flex", alignItems: "center", gap: 0.3, color: "#364FD2", fontSize: "1rem", fontWeight: 600, cursor: "pointer" }}
					onClick={() => setOpen(true)}
				>
					<AddIcon />
					Add Event
				</Typography>
			</Box>
			{renderEvents()}
			{renderNewEventModal()}
		</Box>
	);
};

export default BatchEvents;
