import { Box, Button, Card, IconButton, Input, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import React, { useState } from "react";
import { API_URL } from "../../../../Config";
import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addManualMaterialThunk } from "../../../../Store/actions/materialAction";
import { addReference } from "../../../../Store/slices/referencesSlice";
import { useLocation } from "react-router-dom";

const PdfReferencesUploadModal = ({ open, setOpen }) => {
	const [fileName, setFileName] = useState("");
	const [uploading, setUploading] = useState(false);
	const [selectedCardType, setSelectedCardType] = useState("Document");
	const [data, setData] = useState();
	const [referenceName, setReferenceName] = useState("");
	const [referenceUrl, setReferenceUrl] = useState("");

	const location = useLocation();

	const topicId = location.pathname.split("/")[4];

	const handleFileChange = (event) => {
		const file = event.target.files?.[0];
		if (file) {
			setData(file);
			setFileName(file.name);
		}
	};

	const dispatch = useDispatch();

	const uploadClick = async () => {
		if (!selectedCardType) return;
		if (selectedCardType === "Document" && !data) return;
		if (selectedCardType === "URL" && !referenceUrl) return;

		let material;

		if (selectedCardType === "Document") {
			material = new FormData();
			material.append("reference_material", data);
			material.append("reference_name", referenceName);
			material.append("node_id", topicId);
		} else if (selectedCardType === "URL") {
			material = {
				reference_name: referenceName,
				reference_url: referenceUrl,
				node_id: topicId,
			};
		}

		setUploading(true);
		try {
			const response = await dispatch(addManualMaterialThunk(material));
			if (response?.payload?.data) {
				dispatch(addReference(response?.payload?.data));
				setUploading(false);
				setReferenceName("");
				setReferenceUrl("");
				setFileName("");
				setData();
				setOpen(false);
			}
		} catch (error) {
			setUploading(false);
			console.error(error);
		}
	};

	return (
		<Box>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
			>
				<ModalDialog
					sx={{
						minWidth: 800,
					}}
				>
					<Typography level='h4'>Add Reference Manually</Typography>
					<ModalClose />
					<Box sx={{ display: "flex", flexDirection: "column", justifyItems: "center", gap: ".5rem" }}>
						<Box
							sx={{
								display: "flex",
								gap: 1,
								flexDirection: "column",
							}}
						>
							<Typography>Reference Name</Typography>
							<Input
								placeholder='e.g. Compiler Design'
								sx={{}}
								value={referenceName}
								onChange={(e) => setReferenceName(e.target.value)}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 1,
								flexDirection: "column",
							}}
						>
							<Typography>Reference Type</Typography>
							<Box
								sx={{
									display: "flex",
									gap: 1,
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Card
									sx={{
										cursor: "pointer",
										backgroundColor: selectedCardType === "URL" ? "#f5f5ff" : "#f4f4f4",
										color: selectedCardType === "URL" ? "#3838f1" : "black",
										borderRadius: "8px",

										border: selectedCardType === "URL" ? "2px solid #3838f1" : "none",
										p: 1,
										"&:hover": {
											backgroundColor: selectedCardType === "URL" ? "primary.main" : "background.level1",
										},
										width: "50%",
									}}
									onClick={() => setSelectedCardType("URL")}
								>
									<Typography
										level='h6'
										sx={{
											fontWeight: "600",
										}}
									>
										Web link
									</Typography>

									<Typography level='body-md'>Add any secure web url</Typography>
								</Card>
								<Card
									sx={{
										cursor: "pointer",
										backgroundColor: selectedCardType === "Document" ? "#f5f5ff" : "#f4f4f4",
										color: selectedCardType === "Document" ? "#3838f1" : "black",
										borderRadius: "8px",
										border: selectedCardType === "Document" ? "2px solid #3838f1" : "none",
										p: 1,
										"&:hover": {
											backgroundColor: selectedCardType === "Document" ? "primary.main" : "background.level1",
										},
										width: "50%",
									}}
									onClick={() => setSelectedCardType("Document")}
								>
									<Typography
										level='h6'
										sx={{
											fontWeight: "600",
										}}
									>
										Document
									</Typography>

									<Typography level='body-md'>Add files (PDF, DOC, PPT)</Typography>
								</Card>
							</Box>
						</Box>
						{selectedCardType === "URL" && (
							<Box
								sx={{
									display: "flex",
									gap: 1,
									flexDirection: "column",
								}}
							>
								<Typography>Web Link</Typography>
								<Input
									placeholder='https://example.com'
									value={referenceUrl}
									onChange={(e) => setReferenceUrl(e.target.value)}
								/>
							</Box>
						)}
						{selectedCardType === "Document" && (
							<Box
								sx={{
									display: "flex",
									gap: 1,
									flexDirection: "column",
								}}
							>
								<Typography>File</Typography>
								<Box
									sx={{
										alignItems: "center",
										backgroundColor: "white",
										borderRadius: "md",
										p: 0.5,
										boxShadow: 1,
										border: "1px solid #f4f4f4",
									}}
								>
									<IconButton
										component='label'
										color='primary'
										sx={{
											borderRadius: "md",
											width: "100%",
										}}
									>
										<Input
											type='file'
											slotProps={{
												input: {
													accept: ".pdf,.doc,.docx,.xlsx,.csv,.ppt,.pptx",
												},
											}}
											sx={{ display: "none" }}
											onChange={handleFileChange}
										/>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 1,
												justifyContent: "space-between",
												width: "100%",
												padding: " 0 1rem",
											}}
										>
											<Typography sx={{}}>{fileName ? fileName : "Upload"}</Typography>
											<UploadIcon />
										</Box>
									</IconButton>
								</Box>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							mt: 1,
							display: "flex",
							gap: 1,
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant='outlined'
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button
							variant='solid'
							color='primary'
							disabled={uploading || !referenceName || (selectedCardType === "Document" && !data) || (selectedCardType === "URL" && !referenceUrl)}
							loading={uploading}
							onClick={() => uploadClick(data)}
						>
							Upload
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		</Box>
	);
};

export default PdfReferencesUploadModal;
