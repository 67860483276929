import * as React from "react";
import { Box, Button, Typography, Modal, Stepper, Step, StepLabel, Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { incrementStep, setActiveStep, decrementStep } from "../../Store/slices/taskDetailsSlice";
import { AddTaskThunk, getTasksByNodeIdThunk } from "../../Store/actions/taskAction";
import { generateTaskForVideoThunk } from "../../Store/actions/videoAction";
import { bgColorObj, colorObj } from "../../helpers/styleConfig";
import { Textarea } from "@mui/joy";
import { render } from "@testing-library/react";
import { Duration } from "luxon";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getTopicInfoByVideoIdFromNodes, getTopicInfoFromNode } from "../../helpers/node";
import Loader from "../utils/Loader";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1000,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const steps = ["Step 1", "Step 2", "Step 3"];

function CreateTask({ open, handleClose, topicId }) {
	const dispatch = useDispatch();
	const location = useLocation();
	const { taskData, activeStep, taskAddloader, statusCheckLoading, error: statusError, taskByNodeId } = useSelector((state) => state.task);
	const [selectedVideo, setSelectedVideo] = React.useState("");
	const [status, setStatus] = React.useState("");
	const navigate = useNavigate();
	const courseId = location.pathname.split("/")[2];
	const { summaryLoading, error, videoError, videoLoading: loading, videoData } = useSelector((state) => state.video);
	const { courseStatus, statusLoader, courseData } = useSelector((state) => state.course);
	const [checkedItems, setCheckedItems] = React.useState([]);

	const [showSummary, setShowSummary] = React.useState();
	const handleVideoSelect = (videoId, status) => {
		setSelectedVideo(videoId === selectedVideo ? "" : videoId);
		setStatus(status);
		if (selectedVideo === videoId) return;
		const nodeInfo = getTopicInfoByVideoIdFromNodes(courseData?.data, videoId);
		dispatch(getTasksByNodeIdThunk(nodeInfo?.node_id));
	};

	const handleModalClose = () => {
		handleClose();
		setShowSummary(null);
	};

	const alreadyAddedTask = (questionId, taskByNodeId) => {
		if (taskByNodeId?.data?.length > 0) {
			return taskByNodeId?.data?.find((task) => task.question_id === questionId);
		}
		return false;
	};

	const handleGenerateTask = () => {
		dispatch(generateTaskForVideoThunk(selectedVideo));
	};

	const handleCheckboxChange = (event, itemId) => {
		setShowSummary(showSummary === itemId ? "" : itemId);
		if (event.target.checked) {
			setCheckedItems([...checkedItems, itemId]);
		} else {
			setCheckedItems(checkedItems.filter((id) => id !== itemId));
		}
	};

	function AddTask() {
		const tasks = taskData?.data
			?.filter((item) => checkedItems.includes(item.id))
			?.map((item) => ({
				question_id: item.id,
				points: item.points || 20,
			}));
		const nodeId = taskData?.data[0].node_id;

		dispatch(AddTaskThunk({ tasks, nodeId, navigate, courseId }));
	}
	function formatTime(seconds) {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const secs = seconds % 60;

		const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
		return formattedTime;
	}

	React.useEffect(() => {
		if (!open) {
			setSelectedVideo("");
			setStatus("");
			dispatch(setActiveStep());
		}
	}, [open]);

	const renderVideoSummary = (summary) => {
		return (
			<Box sx={{ backgroundColor: "#F5F5FF", padding: 2, px: 3, my: 2, borderRadius: "8px" }}>
				<Typography
					variant='h6'
					sx={{ color: "black", fontWeight: "bold", py: 2 }}
				>
					Summary
				</Typography>
				{summary?.summary?.topics?.map((t, i) => (
					<Box
						key={i}
						sx={{ color: "black", fontWeight: "bold", mb: 3 }}
					>
						<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
							<Typography
								variant='h6'
								component='span'
								sx={{ color: "black", fontWeight: "bold" }}
							>
								•
							</Typography>
							<Typography sx={{ color: "#3838F1" }}>{formatTime(t?.start_time)}</Typography>
							<Typography
								variant='body1'
								sx={{ fontSize: "15px" }}
							>
								{t?.topic}
							</Typography>
						</Box>
						{summary?.summary?.points_to_remember && (
							<Typography
								variant='body2'
								sx={{ opacity: 0.8, ml: 1 }}
							>
								{summary?.summary?.points_to_remember[i]}
							</Typography>
						)}
						{t?.explanation && (
							<Typography
								variant='body2'
								sx={{ opacity: 0.8, mt: 0.2, ml: 1 }}
							>
								{t.explanation}
							</Typography>
						)}
					</Box>
				))}
			</Box>
		);
	};

	return (
		<Modal
			open={open}
			onClose={handleModalClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<Box sx={style}>
				{/* header */}
				<Typography
					id='modal-modal-title'
					sx={{
						marginBottom: ".4rem",
						fontSize: "1.2rem",
						fontWeight: "700",
						textAlign: "center",
					}}
					component='h2'
				>
					Create Task with AI
				</Typography>
				{/* steps */}
				<Stepper activeStep={activeStep}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>

				{/* steps-content */}
				{activeStep === 0 && (
					<>
						<Typography sx={{ mt: 2, mb: 2, fontSize: "14px" }}>
							To ensure the Task is more accurate and appropriate to the Topic, choose the Videos or references based on which the Task is created
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mb: 1,
							}}
						>
							<Typography
								variant='subtitle1'
								sx={{ fontWeight: "600", fontSize: "14px" }}
							>
								Topic Videos
							</Typography>
							{/* <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", fontSize: "14px" }}
              >
                Status
              </Typography> */}
						</Box>

						{/* scrollable content  */}
						<Box sx={{ height: "400px", overflowY: "auto" }}>
							{loading ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										my: 2,
										fontSize: "14px",
									}}
								>
									<Loader />
								</Box>
							) : videoError ? (
								<Box sx={{ color: "error.main", my: 2, justifyContent: "center", display: "flex" }}>
									<Typography>{videoError}</Typography> {/*error  handling for videos */}
								</Box>
							) : videoData && videoData.length > 0 ? (
								<>
									{/*error  handling for videos  task generation request*/}
									{error && <Typography sx={{ color: "error.main" }}>{error}</Typography>}
									{videoData.map((video) => (
										<Box>
											<Box
												key={video?.video_id}
												onClick={() => setShowSummary(showSummary === video?.video_id ? "" : video?.video_id)}
												sx={{ display: "flex", alignItems: "center", mb: 1, cursor: "pointer" }}
											>
												<Checkbox
													checked={selectedVideo === video?.video_id}
													onChange={() => handleVideoSelect(video?.video_id, video?.status)}
												/>
												<img
													src={`/_thumbnail.jpg`}
													alt={`Video thumbnail`}
													style={{
														width: "60px",
														height: "35px",
														margin: "0 10px",
													}}
												/>
												<Typography
													variant='body2'
													sx={{
														flexGrow: 1,
														overflow: "hidden",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
													}}
												>
													{video?.title || video?.url}
													&nbsp; <span style={{ fontWeight: "bold" }}>{video?.video_id}</span>
												</Typography>
												{/* Status Typography component is commented out */}
												<Link
													to={video?.url}
													target='_blank'
													style={{ marginRight: "10px", color: "ActiveBorder", marginTop: "5px" }}
												>
													<OpenInNewIcon fontSize='12px' />
												</Link>
												<Typography sx={{ color: colorObj[video.status], backgroundColor: bgColorObj[video.status] }}>{video.status}</Typography>
											</Box>
											{showSummary === video?.video_id && renderVideoSummary(JSON.parse(video?.summary))}
										</Box>
									))}
								</>
							) : (
								<Typography
									variant='body1'
									sx={{ my: 2 }}
								>
									No videos available.
								</Typography>
							)}
						</Box>
						<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
							<Button
								variant='contained'
								onClick={handleGenerateTask}
								disabled={!selectedVideo || summaryLoading || statusCheckLoading || !(status === "COMPLETED")}
							>
								Generate Task
							</Button>
						</Box>
					</>
				)}
				{activeStep === 1 && (
					<>
						<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
							{/* Fixed header */}
							<Box sx={{ p: 2, borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
								<Typography sx={{ fontWeight: "600", fontSize: "14px" }}>Task Title</Typography>
								<Typography sx={{ fontSize: "12px" }}>{getTopicInfoFromNode(courseData?.data, topicId)?.node_name}</Typography>
								{error && <Typography sx={{ fontSize: "12px", color: "red" }}>{error}</Typography>}
							</Box>

							{/* Scrollable content */}
							<Box sx={{ height: "400px", overflowY: "auto" }}>
								{statusCheckLoading ? (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: "100%",
										}}
									>
										<CircularProgress />
									</Box>
								) : taskData?.data && taskData?.data.length > 0 ? (
									taskData?.data.map((item, index) => (
										<Box
											key={item.id}
											sx={{ my: 3, px: 2 }}
										>
											<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												{alreadyAddedTask(item.id, taskByNodeId) ? (
													<Box>
														<Typography sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
															{`Question ${index + 1}:`}
															&nbsp;{" "}
															<Typography
																sx={{
																	color: "#509b64",
																	backgroundColor: "#E1F7E7",
																	paddingY: "5px",
																	paddingX: "8px",
																	fontSize: "10px",
																	borderRadius: "5px",
																	fontWeight: "400",
																}}
															>
																Already Added
															</Typography>
														</Typography>
													</Box>
												) : (
													<Box sx={{ display: "flex", alignItems: "center" }}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={checkedItems.includes(item.id)}
																	onChange={(e) => handleCheckboxChange(e, item.id)}
																/>
															}
															label={<Typography sx={{ fontWeight: "bold" }}>{`Question ${index + 1}:`}</Typography>}
															sx={{ mb: 1, fontWeight: "600" }}
														/>
													</Box>
												)}
												<Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
													{item?.relevance < 7 ? (
														<Typography
															sx={{
																color: "#FF5C5C",
																backgroundColor: "#FFECEC",
																paddingY: "5px",
																paddingX: "10px",
																fontSize: "12px",
																borderRadius: "10px",
															}}
														>
															Not Recommended
														</Typography>
													) : (
														""
													)}{" "}
													Relevance: {item?.relevance}
												</Typography>
											</Box>
											<Typography sx={{ fontSize: "14px", my: 2 }}>{item.question}</Typography>
											{item?.constraints && (
												<>
													<Typography sx={{ fontWeight: "600", fontSize: "14px", mb: 1 }}>Constraints:</Typography>
													<Typography sx={{ fontSize: "14px", mb: 2 }}>{item.constraints}</Typography>
													<Typography sx={{ fontSize: "14px", mb: 2 }}>{item.test_cases}</Typography>
												</>
											)}
										</Box>
									))
								) : (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: "100%",
										}}
									>
										<Typography>No questions available.</Typography>
									</Box>
								)}
							</Box>

							{/* Fixed footer with buttons */}
							<Box
								sx={{
									p: 2,
									borderTop: "1px solid rgba(0, 0, 0, 0.12)",
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Button
									variant='outlined'
									onClick={() => dispatch(decrementStep())}
								>
									Back
								</Button>
								<Button
									variant='outlined'
									onClick={handleClose}
								>
									{" "}
									Cancel
								</Button>
								<Button
									variant='contained'
									color='primary'
									disabled={taskAddloader || checkedItems.length === 0}
									onClick={() => AddTask()}
								>
									{taskAddloader ? "Adding Task " : "Confirm & Add Task"}
								</Button>
							</Box>
						</Box>
					</>
				)}
			</Box>
		</Modal>
	);
}

export default CreateTask;
