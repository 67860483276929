import { createAsyncThunk } from "@reduxjs/toolkit";
import { addTeacher, assignTeacher, generateOtp, unassignTeacher, verifyOtp } from "../services/authenticateApiService";

export const generateOtpThunk = createAsyncThunk("auth/generateOtp", async (email, { rejectWithValue }) => {
	try {
		const response = await generateOtp(email);
		return response;
	} catch (error) {
		return rejectWithValue(error.response?.data || "Error in generating OTP");
	}
});

export const verifyOtpThunk = createAsyncThunk("auth/verifyOtp", async (verifyData, { rejectWithValue }) => {
	try {
		const response = await verifyOtp(verifyData);
		return response;
	} catch (error) {
		return rejectWithValue(error?.response?.data || "Error in verifying OTP");
	}
});

export const addTeacherThunk = createAsyncThunk("auth/addTeacher", async (teacherData, { rejectWithValue }) => {
	try {
		const response = await addTeacher(teacherData);
		return response;
	} catch (error) {
		return rejectWithValue(error?.response?.data || "Error in adding teacher");
	}
});

export const assignTeacherThunk = createAsyncThunk("auth/assignTeacher", async (teacherData, { rejectWithValue }) => {
	try {
		const response = await assignTeacher(teacherData);
		return response;
	} catch (error) {
		return rejectWithValue(error?.response?.data || "Error in assigning teacher");
	}
});

export const unassignTeacherThunk = createAsyncThunk("auth/unassignTeacher", async (teacherData, { rejectWithValue }) => {
	try {
		const response = await unassignTeacher(teacherData);
		return response;
	} catch (error) {
		return rejectWithValue(error?.response?.data || "Error in unassigning teacher");
	}
});
