import { Box, CircularProgress } from "@mui/joy";
import React from "react";

const Loader = () => {
	return (
		<Box>
			<CircularProgress thickness={4} />
		</Box>
	);
};

export default Loader;
