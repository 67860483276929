import React, { useEffect, useState } from "react";
import "./AddCourse.css";
import "../../Common/Common.css";
import { Input, Option, Select, Button, CircularProgress, Box, Typography } from "@mui/joy";
import ExcelReader from "../ExcelReader/ExcelReader";
import Card, { StepProgress } from "../utils/utils";
import AccordionUtils from "../utils/Accordion";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCourseDetailsThunk } from "../../Store/actions/courseAction";
import WithAuth from "../utils/WithAuth";
import { addCourse } from "../../Store/services/coursesApiService";
import { UserRole } from "../../Constants/user-role";
import { fetchAllTenantsThunk, getCoursesByTenantIdThunk } from "../../Store/actions/tenantAction";
import { selectTenant } from "../../Store/slices/batchSlice";

const AddCourse = () => {
	const dispatch = useDispatch();
	const [courseName, setCourseName] = useState("");
	const [difficulty, setDifficulty] = useState("BEGINNER");
	const [currentStep, setcurrentStep] = useState(1);
	const [excelData, setExcelData] = useState([]);
	const [selectedStyleCard, setSelectedStyleCard] = useState("Cohort Based Course");
	const [selectedCard, setSelectedCard] = useState("Create from Scratch");
	const { step } = useParams();
	const [addCourseloading, setAddCourseLoading] = useState(false);
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.user);
	const { courseData, loading, error } = useSelector((state) => state.course);
	const { tenants, selectedTenant } = useSelector((state) => state.batch);

	const addCourseData = async () => {
		try {
			if (!courseName || !difficulty || !excelData) return;
			const courseDetails = {
				courseName,
				tenant_id: user?.tenant_id || selectedTenant,
				courseDescription: "This is a sample course",
				difficulty,
				courseStyle: "COHORT_BASED",
			};
			if (selectedCard === "Create from Spreadsheet") {
				setAddCourseLoading(true);
				const response = await addCourse(courseDetails, excelData);
				setAddCourseLoading(false);
				navigate(`/course/${response?.data?.data?.course_id}`);
			} else if (selectedCard === "Create from Scratch") {
				setAddCourseLoading(true);
				const response = await addCourse(courseDetails, []);
				setAddCourseLoading(false);
				navigate(`/course/${response?.data?.data?.course_id}`);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (user?.role === UserRole.ADMIN) dispatch(fetchAllTenantsThunk());
		if (step) {
			setcurrentStep(parseInt(step));
		}
	}, []);

	const handlePartnerChange = (event, newValue) => {
		const tenantId = newValue;
		dispatch(selectTenant(tenantId));
		if (tenantId) dispatch(getCoursesByTenantIdThunk(tenantId));
	};

	const steps = [
		{ number: "01", text: "Add Course Details" },
		// { number: "02", text: "Add Content" },
	];

	return (
		<div className='add-course-container'>
			<div className='header'>
				<span>New Course</span>
				{/* <div className='button-container'>
					<button>Save As Draft</button>
					<button>Publish</button>
				</div> */}
			</div>
			<StepProgress
				currentStep={currentStep}
				steps={steps}
			/>

			<div className='content'>
				{currentStep === 1 && (
					<>
						<div className='input-field'>
							<div className='left'>
								<div className='input-container'>
									<span>Name of the Course</span>
									<Input
										placeholder='e.g. Python'
										size='sm'
										// variant="plain"
										slotProps={{
											input: {
												autoFocus: true,
												maxLength: 64,
											},
										}}
										value={courseName}
										onChange={(e) => {
											setCourseName(e.target.value);
										}}
										sx={{
											"--Input-focusedThickness": "0rem",
											"--Input-radius": `8px`,
											"--Input-decoratorChildHeight": `5`,
											"--Input-paddingInline": "0",
										}}
									/>
								</div>
								{user?.role === UserRole.ADMIN && (
									<div
										className='input-container'
										style={{ margin: "2rem 0" }}
									>
										<span>Choose a partner to which you want to add the course</span>
										<Select
											variant='plane'
											placeholder='Select Partner'
											value={selectedTenant || ""}
											onChange={handlePartnerChange}
											sx={{ my: 2 }}
											style={{ backgroundColor: "#F4F4F4" }}
										>
											{tenants?.data?.length > 0 ? (
												tenants?.data?.map((partner) => (
													<Option
														key={partner.tenant_id}
														value={partner.tenant_id}
													>
														{partner.name}
													</Option>
												))
											) : (
												<Option value=''>No Partners found</Option>
											)}
										</Select>
									</div>
								)}
							</div>

							<div className='right'>
								<div className='input-container'>
									<span>Difficulty Level</span>
									<Select
										defaultValue={difficulty}
										variant='plane'
										// onChange={(e, newValue) => {
										// 	setDifficulty(newValue);
										// }}
										style={{ backgroundColor: "#F4F4F4" }}
									>
										<Option value='BEGINNER'>Beginner</Option>
										{/* <Option value='INTERMEDIATE'>Intermediate</Option>
										<Option value='ADVANCED'>Advanced</Option> */}
									</Select>
								</div>
							</div>
						</div>
						<div
							className='course-type'
							style={{ margin: "-1rem 0" }}
						>
							<div className='main-left'>
								<div className='course-header'> Course Style</div>
								<div className='card-container'>
									<Card
										title='Self Paced Course'
										disabled={true}
										description='No specific schedule, students access at own pace'
										// onClick={() => setSelectedStyleCard("Self Paced Course")}
										selected={selectedStyleCard === "Self Paced Course"}
									/>
									<Card
										title='Cohort Based Course'
										description='Specific schedule, drip-wise student content distribution'
										onClick={() => setSelectedStyleCard("Cohort Based Course")}
										selected={selectedStyleCard === "Cohort Based Course"}
									/>
								</div>
							</div>
							<div className='main-right'>
								<div className='course-header'> Course Creation Method</div>
								<div className='card-container'>
									{/* <Card
										title='Create from Spreadsheet'
										description=' Upload a spreadsheet with your detailed course curriculum
                           details to autocreate.'
										onClick={() => setSelectedCard("Create from Spreadsheet")}
										selected={selectedCard === "Create from Spreadsheet"}
									/> */}

									<Card
										title='Create from Scratch'
										description=' Create the course curriculum by adding modules and topics
                individually.'
										onClick={() => setSelectedCard("Create from Scratch")}
										selected={selectedCard === "Create from Scratch"}
									/>
								</div>
								{selectedCard === "Create from Spreadsheet" && (
									<>
										<Link
											to={`${process.env.PUBLIC_URL}/sample_course_template.xlsx`}
											target='_blank'
											style={{ textDecoration: "none" }}
											download='sample_course_template.xlsx'
										>
											<Typography sx={{ my: 1, justifySelf: "flex-end", color: "blue", fontSize: "1rem", textDecoration: "none", cursor: "pointer" }}>
												Download Sample template
											</Typography>
										</Link>
										<ExcelReader setExcelData={setExcelData} />
									</>
								)}
							</div>
						</div>
						{selectedCard === "Create from Scratch" && (
							<Button
								sx={{ backgroundColor: "#F4F4F4", my: 2, width: "50%", margin: "2rem auto" }}
								variant='plain'
								disabled={!courseName || !difficulty || (user?.role === UserRole.ADMIN && !selectedTenant)}
								onClick={addCourseData}
								loading={addCourseloading}
							>
								Create new course
							</Button>
						)}
						{/* {selectedCard === "Create from Spreadsheet" && (
							<Button
								sx={{ backgroundColor: "#F4F4F4", margin: "2rem auto", width: "50%" }}
								variant='plain'
								disabled={!courseName || !difficulty || excelData.length === 0}
								onClick={addCourseData}
								loading={addCourseloading}
							>
								Upload
							</Button>
						)} */}
					</>
				)}
				{currentStep === 2 && (
					<>
						{" "}
						<div className='module-container'>
							<div className='module-header'>
								<h3>Modules</h3>
								<button className='add-module-btn'>+ Add New Module</button>
							</div>
							<div className='content-box'>
								{loading ? (
									<div>
										<CircularProgress variant='soft' />
										<p>Creating the Course Structure based on the Spreadsheet uploaded...</p>
									</div>
								) : error["fetchCourseDetails"] ? (
									<div>{error["fetchCourseDetails"]}</div>
								) : !courseData?.data || courseData?.data?.children.length === 0 ? (
									<div>No topics found. Please add some course content.</div>
								) : (
									<AccordionUtils courseData={courseData?.data} />
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default WithAuth(AddCourse);
