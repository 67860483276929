import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const fetchCourseDetails = async (courseId, batchId) => {
	if (batchId) {
		const response = await axios.get(`${API_URL}/course/course-details?course_id=${courseId}&batch_id=${batchId}`, {
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		});
		return response.data;
	}
	const response = await axios.get(`${API_URL}/course/course-details?course_id=${courseId}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const addCourse = async (courseDetails, data) => {
	try {
		const addedCourse = await axios.post(
			`${API_URL}/course`,
			{
				tenant_id: courseDetails.tenant_id,
				name: courseDetails.courseName,
				description: courseDetails.courseDescription,
				difficulty_level: courseDetails.difficulty,
				course_style: courseDetails.courseStyle,
			},
			{
				headers: {
					Authorization: `Bearer ${fetchToken()}`,
				},
			}
		);
		const course_id = addedCourse?.data?.data?.course_id;

		const rootNode = await axios.post(`${API_URL}/node`, {
			parent_node_id: null,
			node_type: "ROOT_NODE",
			node_label: "ROOT NODE",
			course_id: course_id,
		});
		if (data.length === 0) {
			return addedCourse;
		}
		for (const item of data) {
			if (item && item["MODULE"] && item["TOPICS"]) {
				const moduleNode = await axios.post(`${API_URL}/node`, {
					parent_node_id: rootNode?.data?.data?.node_id,
					node_type: "MODULE",
					node_name: item["MODULE"],
					course_id: course_id,
				});

				let videosArray = [];
				let materialArray = [];

				// Check if item.video_urls is defined and not empty
				if (item.video_urls && item.video_urls.length > 0) {
					for (const video of item.video_urls) {
						const videoResult = await axios.post(`${API_URL}/video`, {
							url: video?.video_url,
							title: video?.video_title,
							points: video?.points || 0,
							duration: convertDecimalToTime(video?.video_hours),
							parent_node_id: moduleNode?.data?.data?.node_id,
						});
						videosArray.push(videoResult?.data?.data?.video_id);
					}
				}

				// Proceed to create the TOPIC node even if videosArray is empty
				const topicNode = await axios.post(`${API_URL}/node`, {
					parent_node_id: moduleNode?.data?.data?.node_id,
					node_type: "TOPIC",
					node_name: item["TOPICS"],
					course_id: course_id,
					videos: videosArray.length > 0 ? videosArray : null,
					// materials: materialArray, // Add materials if needed
					duration: convertDecimalToTime(item["VIDEO_HOURS"] || 0),
					points: item["POINTS"] || 0,
				});
			}
		}
		return addedCourse;
	} catch (e) {
		console.error(e);
	}
};

export const addModule = async (rootNodeId, moduleData) => {
	try {
		const response = await axios.post(`${API_URL}/node`, {
			parent_node_id: rootNodeId,
			node_type: "MODULE",
			node_name: moduleData.moduleName,
			course_id: moduleData.courseId,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.message || "An error occurred while adding the module.");
	}
};

export const addTopic = async (moduleNodId, topicData) => {
	try {
		const response = await axios.post(`${API_URL}/node`, {
			parent_node_id: moduleNodId,
			node_type: "TOPIC",
			node_name: topicData.topicName,
			course_id: topicData.courseId,
			videos: topicData?.videos || null,
			duration: topicData?.duration || null,
			points: topicData?.points || null,
			description: topicData?.topicDescription,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.message || "An error occurred while adding the topic.");
	}
};

export const fetchQuestionData = async (videoId) => {
	const response = await axios.get(`${API_URL}/question?video_id=${videoId}`);
	return response.data;
};

export const fetchCourses = async (query) => {
	try {
		const queryString = new URLSearchParams(query).toString();
		const response = await axios.get(`${API_URL}/course/admin-courses?${queryString}`, {
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		});
		return response.data;
	} catch (error) {
		throw new Error(error.message || "An error occurred while fetching courses.");
	}
};

function convertDecimalToTime(decimal) {
	// Calculate the total number of hours
	const totalHours = decimal * 24;

	// Extract hours, minutes, and seconds
	const hours = Math.floor(totalHours);
	const minutes = Math.floor((totalHours - hours) * 60);
	const seconds = Math.round(((totalHours - hours) * 60 - minutes) * 60);

	// Format the result as 'HH:MM:SS'
	const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

	return formattedTime;
}

export const updateTopicDetails = async (topicData) => {
	const response = await axios.patch(`${API_URL}/node`, topicData, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};
