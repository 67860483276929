import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dropdown, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import "../../../Common/Common.css";
import "../Topic.css";
import { Link } from "@mui/material";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";
import TaskModal from "./create-task/task-modal";
import CodeIcon from "@mui/icons-material/Code";
import Loader from "../../utils/Loader";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useLocation } from "react-router-dom";
import { deleteTaskThunk } from "../../../Store/actions/taskAction";
import TaskEditModal from "./edit-task/edit-task-modal";

const Task = () => {
	const dispatch = useDispatch();
	const { taskByNodeId, taskNodeLoader, taskError } = useSelector((state) => state.task);
	const [open, setOpen] = useState(false);
	const [showDeleteModalForTask, setShowDeleteModalForTask] = useState("");
	const [showTaskEditModal, setShowTaskEditModal] = useState("");

	const location = useLocation();

	const node_id = location.pathname.split("/")[4];

	const handleDelete = async (task_id, node_id) => {
		const response = await dispatch(deleteTaskThunk({ task_id, node_id }));
		if (response?.payload?.data) {
			setShowDeleteModalForTask("");
			return;
		}
	};

	const renderDeleteModal = (task) => {
		return (
			<Modal
				open={showDeleteModalForTask === task?.task_id}
				onClose={() => setShowDeleteModalForTask("")}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to delete this task?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							color='primary'
							onClick={() => setShowDeleteModalForTask("")}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							loading={taskNodeLoader}
							onClick={() => {
								handleDelete(task?.task_id, node_id);
							}}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderTaskEditModal = (task) => {
		return (
			<Modal
				open={showTaskEditModal === task?.task_id}
				onClose={() => setShowTaskEditModal("")}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose />
					<Typography level='h4'>Edit Task</Typography>
					<TaskEditModal
						setOpen={setShowTaskEditModal}
						task={task}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderTaskCard = (task) => {
		return (
			task?.question && (
				<>
					<Box
						key={task.task_id}
						sx={{
							backgroundColor: "#f4f4f4",
							borderRadius: "sm",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							p: ".8rem 1rem",
							cursor: "pointer",
							my: 2,
						}}
						onClick={() => {
							setShowTaskEditModal(task?.task_id);
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
							}}
						>
							<CodeIcon
								sx={{
									width: "20px",
									height: "20px",
								}}
							/>
							<Typography
								sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
								noWrap
							>
								{task?.question}
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 2,
							}}
						>
							<Typography
								sx={{ fontSize: "12px" }}
								level='body-md'
							>
								{task.task_type ? "Manual" : "AI Generated"}
							</Typography>
							<Typography
								sx={{ fontSize: "12px" }}
								level='body-md'
							>
								{task.task_type ?? "CODE"}
							</Typography>
							<Typography
								sx={{ fontSize: "12px" }}
								level='body-md'
							>
								{task?.points}pts
							</Typography>
							<Dropdown>
								<MenuButton
									sx={{
										backgroundColor: "transparent",
										border: "none",
										cursor: "pointer",
										padding: 0,
									}}
									onClick={(e) => {
										e.stopPropagation();
									}}
								>
									<MoreHorizIcon />
								</MenuButton>
								<Menu
									modifiers={[
										{
											name: "offset",
											options: {
												offset: ({ placement }) => {
													if (placement.includes("end")) {
														return [8, 20];
													}
													return [50, -20];
												},
											},
										},
									]}
								>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation();
											setShowTaskEditModal(task?.task_id);
										}}
									>
										Edit
									</MenuItem>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation();
											setShowDeleteModalForTask(task?.task_id);
										}}
									>
										<Typography color='danger'>Delete</Typography>
									</MenuItem>
								</Menu>
							</Dropdown>
						</Box>
					</Box>
				</>
			)
		);
	};

	const renderTaskList = () => {
		if (taskNodeLoader) {
			return (
				<Box>
					<Loader />
				</Box>
			);
		} else if (taskError) {
			dispatch(showToast({ message: taskError.message, type: ToastType.ERROR }));
		} else if (taskByNodeId?.data && taskByNodeId?.data.length > 0) {
			return (
				<Box sx={{ maxHeight: "40vh", overflowY: "auto", my: 2 }}>
					<Box
						sx={{
							px: 2,
						}}
					>
						{taskByNodeId?.data.map((task) => {
							return (
								<>
									{renderTaskCard(task)}
									{showDeleteModalForTask === task?.task_id && renderDeleteModal(task)}
									{showTaskEditModal === task?.task_id && renderTaskEditModal(task)}
								</>
							);
						})}
					</Box>
				</Box>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Add Tasks to the Topic for the students to do.
					</Typography>
				</Box>
			);
		}
	};

	const renderTaskModal = () => {
		return (
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose
						variant='plain'
						sx={{ m: 1 }}
					/>
					<Typography
						component='h2'
						id='modal-title'
						level='h4'
						textColor='inherit'
						fontWeight='lg'
						my={1}
					>
						Add Task manually
					</Typography>
					<TaskModal setOpen={setOpen} />
				</ModalDialog>
			</Modal>
		);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "0 1rem",
				}}
			>
				<Box>
					<Typography
						level='h5'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Tasks {taskByNodeId?.data?.length > 0 && `${taskByNodeId?.data?.length}`}
					</Typography>
				</Box>
				<Box>
					<Button
						sx={{ color: "#3838f1", fontWeight: "600", fontSize: "14px", bgcolor: "#f5f5ff", borderRadius: "4px" }}
						variant='primary'
						onClick={() => setOpen(true)}
					>
						Add Task Manually
					</Button>
				</Box>
			</Box>
			<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.30)", my: 1 }}></Box>
			<Box
				sx={{
					borderRadius: "sm",
				}}
			>
				{renderTaskList()}
				{renderTaskModal()}
			</Box>
		</Box>
	);
};

export default Task;
