import { Box, Button, Input, Typography } from "@mui/joy";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateMaterialThunk } from "../../../../Store/actions/materialAction";
import { updateReference } from "../../../../Store/slices/referencesSlice";

const ReferenceEditForm = ({ material, setShowReferenceEditModal }) => {
	const [referenceName, setReferenceName] = useState(material?.name);
	const [referenceUrl, setReferenceUrl] = useState(material?.url);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const handleSubmit = async () => {
		if (referenceName === "" || referenceUrl === "" || (referenceName?.trim() === material?.name?.trim() && referenceUrl.trim() === material?.url.trim())) {
			return;
		}

		const data = {
			name: referenceName,
			url: referenceUrl,
			material_id: material?.material_id,
		};

		setLoading(true);
		const response = await dispatch(updateMaterialThunk(data));

		if (response?.payload?.data) {
			setLoading(false);
			dispatch(updateReference(response.payload.data));
			setShowReferenceEditModal("");
		}
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					flexDirection: "column",
				}}
			>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Reference Name
					</Typography>
					<Input
						placeholder='e.g. Compiler Design'
						sx={{}}
						value={referenceName}
						onChange={(e) => setReferenceName(e.target.value)}
					/>
				</Box>
				<Box>
					<Typography
						level='h6'
						sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
					>
						Reference URL
					</Typography>
					<Input
						placeholder='e.g. https://www.geeksforgeeks.org/compiler-design/'
						sx={{}}
						value={referenceUrl}
						onChange={(e) => setReferenceUrl(e.target.value)}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						gap: 1,
					}}
				>
					<Button
						variant='outlined'
						onClick={() => setShowReferenceEditModal("")}
					>
						Cancel
					</Button>
					<Button
						onClick={handleSubmit}
						disabled={loading || referenceName === "" || referenceUrl === "" || (referenceName?.trim() === material?.name?.trim() && referenceUrl?.trim() === material?.url?.trim())}
						loading={loading}
					>
						Edit
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ReferenceEditForm;
