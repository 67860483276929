import { createAsyncThunk } from "@reduxjs/toolkit";
import { addManualVideo, checkVideosStatus, deleteVideo, fetchVideoSummary, fetchVideosByIds, updateVideo } from "../services/videoApiService";
import { fetchQuestionData } from "../services/coursesApiService";

export const fetchVideoSummaryThunk = createAsyncThunk("task/fetchVideoSummary", async (courseId, { rejectWithValue }) => {
	try {
		const response = await fetchVideoSummary(courseId);
		alert("Task generation request submitted successfully.");
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "error inAn error occurred while generating task");
	}
});

export const checkStatusThunk = createAsyncThunk("task/checkVideoStatus", async (courseId, { rejectWithValue }) => {
	try {
		const videoStatus = await checkVideosStatus(courseId);
		return videoStatus;
	} catch (error) {
		return rejectWithValue(error.message || "Error checking status");
	}
});

export const generateTaskForVideoThunk = createAsyncThunk("task/generateTaskForVideo", async (videoId, { rejectWithValue }) => {
	try {
		const response = fetchQuestionData(videoId);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while generating tasks.");
	}
});

export const fetchVideosByIdsThunk = createAsyncThunk("courseDetails/fetchVideosByIds", async (videoId, { rejectWithValue }) => {
	try {
		const data = await fetchVideosByIds(videoId);
		return data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while fetching course details.");
	}
});

export const addManualVideoThunk = createAsyncThunk("courseDetails/addManualVideo", async (video, { rejectWithValue }) => {
	try {
		const response = await addManualVideo(video);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while adding the video.");
	}
});

export const deleteVideoThunk = createAsyncThunk("courseDetails/deleteVideo", async ({ video_id, node_id }, { rejectWithValue }) => {
	try {
		const response = await deleteVideo(video_id, node_id);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while deleting the video.");
	}
});

export const updateVideoThunk = createAsyncThunk("task/updateVideo", async (video, { rejectWithValue }) => {
	try {
		const response = await updateVideo(video);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});
