import React, { useEffect } from "react";
import WithAuth from "../../../utils/WithAuth";
import { fetchBatchByIdThunk } from "../../../../Store/actions/batchAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/joy";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { fetchCourseDetailsThunk } from "../../../../Store/actions/courseAction";
import { DateTime } from "luxon";
import { fetchEventsByBatchIdThunk } from "../../../../Store/actions/batchEventAction";
import Loader from "../../../utils/Loader";

const ViewBatch = () => {
	const { batchDetails, error, loading } = useSelector((state) => state.batch);
	const { courseData, loading: courseLoading } = useSelector((state) => state.course);
	const { events, loading: eventLoading } = useSelector((state) => state.batchEvent);
	const dispatch = useDispatch();
	const location = useLocation();
	const batchId = location.pathname.split("/")[2];
	useEffect(() => {
		if (batchId) {
			dispatch(fetchBatchByIdThunk(batchId));
			dispatch(fetchEventsByBatchIdThunk(batchId));
		}
	}, [batchId, dispatch]);
	useEffect(() => {
		if (batchDetails) dispatch(fetchCourseDetailsThunk({ courseId: batchDetails?.data?.courses[0], batchId }));
	}, [batchDetails]);

	const renderLeafNode = (childNode, parentNode) => {
		const date = DateTime.fromISO(childNode?.topic_start_date).toFormat("dd MMM");
		return (
			<Box
				sx={{ display: "flex", alignItems: "center", width: "100%", overflowX: "hidden", my: 2 }}
				key={childNode?.node_id}
			>
				<Box sx={{ width: "5%", mr: 2 }}>
					<Typography
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							alignItems: "center",
							color: "#364FD2",
							fontSize: "1rem",
							fontWeight: "600",
							justifyContent: "center",
						}}
					>
						{date}
					</Typography>
				</Box>
				<Box sx={{ backgroundColor: "#f4f4f4", width: "95%", display: "flex", alignItems: "center", p: 1, borderRadius: "10px" }}>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", px: 3 }}>
						<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography>{parentNode?.node_name}</Typography>
								<ChevronRightIcon
									sx={{
										color: "gray",
										mx: 1,
									}}
									fontSize='10px'
								/>
							</Box>
							<Typography>{childNode?.node_name}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
							<Typography
								sx={{ color: "GrayText" }}
								level='title-sm'
							>
								{childNode?.tasks?.length || 0} Task
							</Typography>
							<Typography
								sx={{ color: "GrayText" }}
								level='title-sm'
							>
								{childNode?.videos?.length || 0} Videos
							</Typography>
							<Typography
								sx={{ color: "GrayText" }}
								level='title-sm'
							>
								{childNode?.points}pts
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderTree = (node, parentNode) => {
		if (node?.children?.length > 0) {
			return node?.children?.map((childNode) => renderTree(childNode, node));
		} else {
			return <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>{renderLeafNode(node, parentNode)}</Box>;
		}
	};

	if (loading || courseLoading || eventLoading) {
		return (
			<div>
				<Loader />
			</div>
		);
	}

	return (
		<Box
			sx={{
				height: "35rem",

				backgroundColor: "white",
				padding: 2,
				borderRadius: "10px",
				mt: 2,
			}}
		>
			<Box sx={{ mb: 2 }}>
				<Typography level='h4'>TIMETABLE</Typography>
			</Box>
			<Box sx={{ overflowY: "auto", height: "95%" }}>{renderTree(courseData?.data)}</Box>
		</Box>
	);
};

export default WithAuth(ViewBatch);
