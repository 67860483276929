import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const addTopicSession = async (topicSession) => {
	const response = await axios.post(`${API_URL}/topic-session/admin`, topicSession, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const fetchTopicSessionByBatchId = async (batch_id) => {
	const response = await axios.get(`${API_URL}/topic-session/admin/${batch_id}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const updateTopicSession = async (topicSession) => {
	const response = await axios.patch(`${API_URL}/topic-session/admin/${topicSession.topic_session_id}`, topicSession, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};
