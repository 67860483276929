import React, { useEffect, useState } from "react";
import "./CoursesList.css";
import { useNavigate } from "react-router-dom";

import { Box, Button, Input, Modal, ModalClose, Option, Sheet, Typography } from "@mui/joy";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import "../../Common/Common.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCoursesThunk } from "../../Store/actions/courseAction";
import { ToastType } from "../../Constants/toast-type";
import { showToast } from "../../Store/slices/commonSlice";
import WithAuth from "../utils/WithAuth";
import Loader from "../../Components/utils/Loader";
import useDebounce from "../../hooks/use-debounce";
import PaginationComponent from "../utils/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { getFirstLetterCapitalized } from "../../helpers/text-formatter";

const CoursesList = () => {
	const navigate = useNavigate();
	const [showAddCourseModal, setShowAddCourseModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search, 500);

	//state for multiple filters
	const [filters, setFilters] = useState([]);

	const dispatch = useDispatch();

	const { allCourses, loading, error } = useSelector((state) => state.course);

	useEffect(() => {
		if (debouncedValue) {
			if (currentPage !== 1) setCurrentPage(1);
			else {
				dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: currentPage }));
			}
		} else {
			setCurrentPage(1);
			dispatch(fetchAllCoursesThunk({ page: 1 }));
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (debouncedValue) {
			dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: currentPage }));
		} else {
			dispatch(fetchAllCoursesThunk({ page: currentPage }));
		}
	}, [currentPage]);

	if (error["fetchAllCourses"]) {
		dispatch(showToast({ toastMessage: error["fetchAllCourses"], toastType: ToastType.ERROR }));
	}

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearch(value);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const renderCourseCard = (course) => {
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					p: ".8rem 1rem",
					bgcolor: "white",
					cursor: "pointer",
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				key={course.course_id}
				onClick={() => {
					navigate(`/course/${course.course_id}`);
				}}
			>
				<Box>
					<Typography
						sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
						noWrap
					>
						{getFirstLetterCapitalized(course.name)}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Box>
						<Typography
							sx={{
								fontSize: ".8rem",
							}}
						>
							{course?.difficulty_level}
						</Typography>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderCourses = () => {
		if (loading) {
			return (
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
					<Loader />
				</div>
			);
		} else if (allCourses?.data?.data?.length > 0) {
			// dispatch(showToast({ toastMessage: "Courses loaded successfully", toastType: ToastType.SUCCESS }));
			return (
				<Box
					sx={{
						maxHeight: "70vh",
						overflowY: "auto",
						bgcolor: "white",
						borderRadius: "8px",
					}}
				>
					{allCourses.data?.data?.map((course, i) => renderCourseCard(course))}
				</Box>
			);
		} else {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "50vh",
					}}
				>
					No courses available
				</div>
			);
		}
	};

	const renderPagination = () => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: "2rem",
				}}
			>
				<Box
					sx={{
						display: "flex",
						bgcolor: "white",
						justifyContent: "center",
						alignItems: "center",
						padding: " .5rem",
						borderRadius: "8px",
						gap: 5,
					}}
				>
					<PaginationComponent
						totalPage={allCourses?.data?.totalPages}
						onPageChange={handlePageChange}
						currentPage={currentPage}
					/>
				</Box>
			</Box>
		);
	};

	return (
		<div className='courses-list-container'>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: "1.5rem",
				}}
			>
				<Typography sx={{ marginRight: "0.2rem", fontWeight: 600, fontSize: "1.5rem" }}>Courses {allCourses?.data?.count}</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 3,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							border: "1px solid rgba(0, 0, 0, 0.1)",
							borderRadius: "8px",

							padding: ".1rem .5rem",
						}}
					>
						<SearchIcon />
						<Input
							placeholder='Search Courses'
							value={search}
							sx={{
								bgcolor: "transparent",
								boxShadow: "none",
								border: "none",
								outline: "none",
								"--Input-focusedThickness": "0rem",
							}}
							onChange={handleSearchChange}
						/>
					</Box>
					<Box sx={{ display: "flex", gap: 10 }}>
						<Button
							variant='primary'
							sx={{ bgcolor: "#3838F1" }}
							onClick={() => {
								navigate("addcourse");
							}}
						>
							<Typography sx={{ width: "fit-content", color: "white" }}>Create New Course</Typography>
						</Button>
					</Box>
				</Box>
			</Box>
			{renderCourses()}
			{renderPagination()}
		</div>
	);
};

export default WithAuth(CoursesList);
