import { Box } from "@mui/joy";
import { Pagination } from "@mui/material";
import React from "react";

const PaginationComponent = ({ totalPage, onPageChange, currentPage }) => {
	return (
		<Box>
			<Pagination
				color='primary'
				count={totalPage}
				shape='rounded'
				page={currentPage}
				onChange={(event, page) => {
					onPageChange(page);
				}}
			/>
		</Box>
	);
};

export default PaginationComponent;
