import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import { Box } from "@mui/joy";

const CodeHighlighter = ({ code, onCodeChange, language, height }) => {
	const handleEditorDidMount = (editor, monaco) => {
		// Define a custom theme when the editor is mounted
		monaco.editor.defineTheme("my-theme", {
			base: "vs-dark", // Base can be 'vs', 'vs-dark', or 'hc-black'
			inherit: true, // Inherit from the base theme
			rules: [
				{
					token: "identifier",
					foreground: "9CDCFE",
				},
				{
					token: "identifier.function",
					foreground: "DCDCAA",
				},
				{
					token: "type",
					foreground: "1AAFB0",
				},
			],
			colors: {
				"editor.background": "#1E1E1E", // Example: Dark background color
			},
		});

		// Set the custom theme as the active theme
		monaco.editor.setTheme("my-theme");
	};

	return (
		<Box>
			<Editor
				height={height}
				defaultLanguage={language || "java"}
				value={code}
				theme={"my-theme"} // Use the custom theme
				onChange={onCodeChange}
				onMount={handleEditorDidMount} // Set up the custom theme on mount
			/>
		</Box>
	);
};

export default CodeHighlighter;
