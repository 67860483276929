import { createSlice } from "@reduxjs/toolkit";
import { UserRole } from "../../Constants/user-role";

const initialState = {
	user: null,
	loading: false,
	error: null,
	isAuthenticated: false,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		addUserData: (state, action) => {
			state.user = action.payload;
			state.isAuthenticated = true;
		},
		removeUserData: (state) => {
			state.user = null;
			state.isAuthenticated = false;
		},
	},
});

export const { addUserData, removeUserData } = userSlice.actions;
export default userSlice.reducer;
