import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const addManualMaterial = async (material) => {
	try {
		const response = await axios.post(`${API_URL}/material/add-material`, material, {});
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const fetchMaterialByNode = async (nodeId) => {
	try {
		const response = await axios.get(`${API_URL}/material/get-by-node?node_id=${nodeId}`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteMaterial = async (materialId, node_id) => {
	try {
		const response = await axios.delete(`${API_URL}/material?material_id=${materialId}&node_id=${node_id}`, {
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const updateMaterial = async (material) => {
	try {
		const response = await axios.patch(`${API_URL}/material`, material, {
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
	}
};
