import { Box, Button, Card, Textarea, Typography } from "@mui/joy";
import { TextField, Checkbox, IconButton } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import MCQForm from "./mcq-form";
import CodeForm from "./code-from";
import DocForm from "./doc-form";

const TaskModal = ({ setOpen }) => {
	const { videoData, videoLoading, error } = useSelector((state) => state.video);
	const [showTaskType, setShowTaskType] = useState(true);
	const [questionType, setQuestioinType] = useState("Code");

	const renderQuestion = () => {
		switch (questionType) {
			case "MCQ":
				return (
					<MCQForm
						setOpen={setOpen}
						setShowTaskType={setShowTaskType}
					/>
				);
			case "Code":
				return (
					<CodeForm
						setOpen={setOpen}
						setShowTaskType={setShowTaskType}
					/>
				);
			case "DOC":
				return <DocForm setOpen={setOpen} />;
			default:
				return null;
		}
	};

	const renderQuestionOptions = () => {
		return (
			<Box>
				{showTaskType && (
					<Box>
						<Typography
							level='h6'
							sx={{ color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Task type
						</Typography>
						<Box sx={{ borderBottom: "1px solid rgba(0,0,0,.25)" }}>
							<Box sx={{ display: "flex", gap: 2, my: 2, width: "100%" }}>
								<Card
									variant='outlined'
									color='neutral'
									sx={{
										width: "50%",
										cursor: "pointer",
										maxHeight: "80px",
										bgcolor: `${questionType === "Code" ? "#F5f5ff" : "#f4f4f4"}`,
										border: `${questionType === "Code" ? "2px solid #3838F1" : "none"}`,
									}}
									onClick={() => setQuestioinType("Code")}
								>
									<Typography
										sx={{
											color: `${questionType === "Code" ? "#3838F1" : "black"}`,
											fontWeight: "600",
										}}
									>
										Coding
									</Typography>

									<Typography
										sx={{
											width: "fit-content",

											fontSize: "12px",
										}}
									>
										For actionable learning that needs student to write specific code.
									</Typography>
								</Card>
								<Card
									variant='outlined'
									color='neutral'
									sx={{
										cursor: "pointer",
										width: "50%",
										bgcolor: `${questionType === "MCQ" ? "#F5f5ff" : "#f4f4f4"}`,
										maxHeight: "80px",
										border: `${questionType === "MCQ" ? "2px solid #3838F1" : "none"}`,
									}}
									onClick={() => setQuestioinType("MCQ")}
								>
									<Typography
										sx={{
											color: `${questionType === "MCQ" ? "#3838F1" : "black"}`,
											fontWeight: "600",
										}}
									>
										MCQ
									</Typography>

									<Typography
										sx={{
											fontSize: "12px",
										}}
									>
										For quizzes that tests student’s knowledge on various concepts.
									</Typography>
								</Card>
								<Card
									variant='outlined'
									color='neutral'
									sx={{
										cursor: "pointer",
										width: "50%",
										bgcolor: `${questionType === "DOC" ? "#F5f5ff" : "#f4f4f4"}`,
										maxHeight: "80px",
										border: `${questionType === "DOC" ? "2px solid #3838F1" : "none"}`,
									}}
									onClick={() => setQuestioinType("DOC")}
								>
									<Typography
										sx={{
											color: `${questionType === "DOC" ? "#3838F1" : "black"}`,
											fontWeight: "600",
										}}
									>
										Reading
									</Typography>

									<Typography
										sx={{
											fontSize: "12px",
										}}
									>
										For students to understand concepts from specific documents.
									</Typography>
								</Card>
							</Box>
						</Box>
					</Box>
				)}
				{renderQuestion()}
			</Box>
		);
	};

	const renderStepWiseForm = () => {
		// switch (step) {
		// 	case 1:
		// 		return renderVideoSelection();
		// 	case 2:
		return renderQuestionOptions();
		// }
	};

	return <Box>{renderStepWiseForm()}</Box>;
};

export default TaskModal;
