import React, { useEffect, useState } from "react";
// import coursesList from '../../CoursesList'
import { useLocation, useNavigate } from "react-router-dom";
import "./Course.css";
import "../../Common/Common.css";
import { Button, Input, Modal, ModalClose, Sheet } from "@mui/joy";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AccordionUtils from "../utils/Accordion";
import { addModuleThunk, fetchCourseDetailsThunk } from "../../Store/actions/courseAction";
import { showToast } from "../../Store/slices/commonSlice";
import { ToastType } from "../../Constants/toast-type";
import { checkStatusThunk, fetchVideoSummaryThunk } from "../../Store/actions/videoAction";
import WithAuth from "../utils/WithAuth";
import { addModuleData } from "../../Store/slices/courseSlice";
import Loader from "../utils/Loader";

const Course = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [showAccordian, setShowAccordian] = useState(null);
	const [moduleName, setModuleName] = useState("");
	const [showAddTopicModal, setShowAddTopicModal] = useState(false);
	const [addModuleLoader, setAddModuleLoader] = useState(false);

	const courseId = location?.pathname?.split("/")[2];
	const navigate = useNavigate();
	const { courseData, courseStatus, error, loading, statusLoader } = useSelector((state) => state.course);
	// console.log("courseData", courseData);
	useEffect(() => {
		if (courseId !== undefined) {
			dispatch(fetchCourseDetailsThunk({ courseId }));
			// dispatch(checkStatusThunk(courseId));
			// checkStatusEvery10min();
		}
	}, [courseId]);

	const checkStatusEvery10min = () => {
		setInterval(() => {
			dispatch(checkStatusThunk(courseId));
		}, 10 * 60 * 1000);
	};

	const handleAddModule = async () => {
		if (!moduleName) {
			return;
		}
		setAddModuleLoader(true);
		const response = await dispatch(addModuleThunk({ rootNodeId: courseData?.data?.rootNodeId || courseData?.data?.children[0]?.parent_node_id, moduleData: { moduleName, courseId } }));
		if (response?.payload?.data) {
			setAddModuleLoader(false);
			dispatch(addModuleData({ moduleData: response?.payload }));
			setShowAddTopicModal(false);
			setModuleName("");
		}
	};

	if (loading) {
		return (
			<div style={{}}>
				<Loader />
			</div>
		);
	}

	if (error["fetchCourseDetails"]) {
		dispatch(showToast({ toastMessage: error["fetchCourseDetails"], toastType: ToastType.ERROR }));
	}

	const renderCourseTopic = (topic) => {
		return (
			<>
				<div
					className='topic'
					key={topic.node_id}
					onClick={() => {
						if (showAccordian === topic.node_id) {
							setShowAccordian(null);
							return;
						}
						setShowAccordian(topic.node_id);
					}}
				>
					<h3 className='topic-name'>{topic.node_name}</h3>
					<div className='action-button'>
						{/* <button className='option-button'>Edit</button>
						<button
							className='delete-button'
							onClick={(event) => {}}
						>
							Delete
						</button> */}
						<button
							style={{ backgroundColor: "transparent", color: "blue", padding: 0, outline: "none", border: "none", cursor: "pointer" }}
							onClick={() => {
								navigate(`addTopic?parentId=${topic.node_id}`);
							}}
						>
							{" "}
							+ Create new topic
						</button>
					</div>
				</div>
				{showAccordian === topic.node_id && renderSubTopics(topic)}
			</>
		);
	};

	const renderSubTopics = (topic) => {
		return <AccordionUtils courseData={topic} />;
	};

	const renderAllTopics = () => {
		const renderTopics = () => {
			if (courseData !== null) {
				return (
					<div className='topics-list'>
						{courseData?.data?.children?.map((topic, i) => {
							return renderCourseTopic(topic);
						})}
					</div>
				);
			} else {
				return <div>No topics available</div>;
			}
		};

		return (
			<>
				<div className='header'>
					<h2>{courseData?.data?.name}</h2>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						{/* <button
							style={{ marginRight: "0.5rem" }}
							className='option-button'
							onClick={() => dispatch(fetchVideoSummaryThunk(courseId))}
						>
							Generate Summary
						</button> */}
						<span
							style={{
								marginRight: "0.5rem",
								fontSize: "1rem",
								fontWeight: "bold",
								color: "green",
							}}
						>
							{courseData?.data?.points} Points
						</span>
						<Button
							onClick={(event) => {
								event.stopPropagation();
								setShowAddTopicModal(true);
							}}
							sx={{ marginLeft: ".5rem" }}
							className='option-button'
						>
							Add Module
						</Button>
					</div>
				</div>
				{renderTopics()}
			</>
		);
	};

	return (
		<div className='course-container'>
			<Modal
				aria-labelledby='modal-title'
				aria-describedby='modal-desc'
				open={showAddTopicModal}
				onClose={() => {
					setModuleName("");
					setShowAddTopicModal(false);
				}}
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<Sheet
					variant='plain'
					sx={{
						width: "30%",
						height: "250px",
						borderRadius: "md",
						p: 3,
						boxShadow: "lg",
						position: "relative",
					}}
				>
					<ModalClose
						variant='plain'
						sx={{ m: 0 }}
					/>
					<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
						<h2 style={{ margin: 0, textAlign: "center" }}>Add Module</h2>
						<div
							className='input-field'
							style={{ marginBottom: 50 }}
						>
							<span>Name</span>
							<Input
								className='text-input'
								id='outlined-basic'
								variant='outlined'
								slotProps={{
									input: {
										maxLength: 64,
									},
								}}
								value={moduleName}
								onChange={(e) => {
									setModuleName(e.target.value);
								}}
							/>
						</div>
						<Button
							className='default-button'
							sx={{ margin: "0px auto 0px auto", padding: 1 }}
							onClick={handleAddModule}
							loading={addModuleLoader}
							disabled={addModuleLoader}
						>
							Add Module
						</Button>
					</div>
				</Sheet>
			</Modal>

			{renderAllTopics()}
		</div>
	);
};

export default WithAuth(Course);
