import React, { useState } from "react";
import { Box, Input, IconButton, Typography } from "@mui/joy";
import UploadIcon from "@mui/icons-material/Upload";
import Papa from "papaparse";

const CsvParser = ({ setData, mandatoryFields, setError }) => {
	const [fileName, setFileName] = useState("");

	const validateMandatoryFields = (fields) => {
		if (!mandatoryFields?.every((mandatoryField) => fields?.includes(mandatoryField))) {
			setError(`Mandatory columns are required: ${mandatoryFields?.join(", ")}`);
			return false;
		}
		return true;
	};

	const handleFileChange = (event) => {
		setError("");
		setData(undefined);
		const file = event.target.files?.[0];
		if (file) {
			const fileSizeInMB = file.size / (1024 * 1024);
			if (fileSizeInMB > 5) {
				setError("File size exceeds 5MB limit.");
				return;
			}
			setFileName(file.name);
			Papa.parse(file, {
				header: true,
				complete: (results) => {
					if (mandatoryFields && mandatoryFields.length > 0) {
						if (!validateMandatoryFields(results.meta.fields)) {
							return;
						}
					}
					const dataWithLowercaseEmails = results.data
						.map((row) => {
							if (row === undefined || row?.name === "") return;
							if (row?.email) {
								row.email = row.email.toLowerCase();
								return row;
							} else return undefined;
						})
						.filter((row) => row !== undefined);
					setData(dataWithLowercaseEmails);
				},
			});
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				backgroundColor: "background.level1",
				borderRadius: "md",
				p: 0.5,
				boxShadow: 1,
			}}
		>
			<IconButton
				component='label'
				color='primary'
				sx={{
					borderRadius: "md",

					width: "100%",
				}}
			>
				<Input
					type='file'
					slotProps={{
						input: {
							accept: ".csv",
						},
					}}
					sx={{ display: "none" }}
					onChange={handleFileChange}
				/>
				<UploadIcon />
				<Typography sx={{ marginX: 2 }}>{fileName ? fileName : "No file choosen"}</Typography>
			</IconButton>
		</Box>
	);
};

export default CsvParser;
