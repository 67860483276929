import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteBlobThunk, fetchBlobsThunk, uploadFileThunk } from "../actions/blobStorageAction";

const blobSlice = createSlice({
	name: "blob",
	initialState: {
		referenceUrls: [],
		loading: false,
		uploadLoading: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBlobsThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchBlobsThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.referenceUrls = action.payload;
			})
			.addCase(fetchBlobsThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(uploadFileThunk.pending, (state) => {
				state.uploadLoading = true;
				state.error = null;
			})
			.addCase(uploadFileThunk.fulfilled, (state, action) => {
				state.uploadLoading = false;
				state.referenceUrls.push(...action.payload);
			})
			.addCase(uploadFileThunk.rejected, (state, action) => {
				state.uploadLoading = false;
				state.error = action.error.message;
			})
			.addCase(deleteBlobThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(deleteBlobThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.referenceUrls = state.referenceUrls.filter((blob) => blob.name !== action.payload);
			})
			.addCase(deleteBlobThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export default blobSlice.reducer;
