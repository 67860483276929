import { Box, Button, Input, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addTopicThunk } from "../../../../Store/actions/courseAction";

const AddTopicDetials = ({ setActiveTab, setTopicNodeId }) => {
	const [topicName, setTopicName] = useState("");
	const [topicDescription, setTopicDescription] = useState(null);

	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const parentNodeId = location.search.split("=")[1];

	const courseId = location.pathname.split("/")[2];
	const handleAddTopic = async () => {
		if (!topicName) return;
		const response = await dispatch(addTopicThunk({ moduleNodeId: parentNodeId, topicData: { courseId, topicName, topicDescription } }));
		if (response?.payload?.data) {
			setTopicNodeId(response?.payload?.data?.topic_node_id);
			setActiveTab("videos");
			navigate(`/course/${courseId}/topic/${response?.payload?.data?.node_id}`);
		}
	};
	return (
		<Box sx={{ display: "flex", flexDirection: "column", background: "white", p: 4 }}>
			<Box sx={{ mb: 2 }}>
				<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Name</Typography>
				<Input
					placeholder='e.g. Compilers & Assemblers'
					label='Topic Name'
					sx={{ mb: 2 }}
					slotProps={{
						input: {
							autoFocus: true,
							maxLength: 64,
						},
					}}
					value={topicName}
					onChange={(e) => setTopicName(e.target.value)}
				/>
				<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Description</Typography>
				<Textarea
					minRows={3}
					placeholder='Describe in a few lines what the topic is about.'
					label='Topic Description'
					value={topicDescription}
					slotProps={{
						textarea: {
							maxLength: 128,
						},
					}}
					onChange={(e) => setTopicDescription(e.target.value)}
				/>
			</Box>
			<Button
				sx={{
					alignSelf: "flex-end",
				}}
				disabled={!topicName}
				onClick={handleAddTopic}
			>
				Continue
			</Button>
		</Box>
	);
};

export default AddTopicDetials;
