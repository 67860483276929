import { createSlice } from "@reduxjs/toolkit";
import { fetchTeachersByTenant } from "../actions/userAction";

const initialState = {
	allTeachers: [],
	loading: false,
	error: null,
};

const tenantSlice = createSlice({
	name: "tenant",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTeachersByTenant.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchTeachersByTenant.fulfilled, (state, action) => {
				state.loading = false;
				state.allTeachers = action.payload.data;
			})
			.addCase(fetchTeachersByTenant.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export default tenantSlice.reducer;
