import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const generateOtp = async (email) => {
	const response = await axios.post(`${API_URL}/instructor/otp/generate`, {
		email,
	});
	return response.data;
};

export const verifyOtp = async (verifyData) => {
	const response = await axios.post(`${API_URL}/instructor/otp/authenticate`, {
		instructor_id: verifyData?.instructor_id,
		otp: verifyData?.otp,
	});
	return response.data;
};

export const addTeacher = async (teacherData) => {
	const response = await axios.post(`${API_URL}/instructor/`, teacherData, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const assignTeacher = async (teacherData) => {
	const response = await axios.patch(`${API_URL}/instructor/assign`, teacherData, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const unassignTeacher = async (teacherData) => {
	const response = await axios.patch(`${API_URL}/instructor/unassign`, teacherData, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};
