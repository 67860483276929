import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchAllTenants, getCoursesByTenantId } from "../services/tenantApiService";

export const fetchAllTenantsThunk = createAsyncThunk("tenant/fetchAllTenants", async (_, { rejectWithValue }) => {
	try {
		const response = await fetchAllTenants();
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in fetching tenants");
	}
});

export const getCoursesByTenantIdThunk = createAsyncThunk("tenant/getCoursesByTenantId", async (tenantId, { rejectWithValue }) => {
	try {
		const response = await getCoursesByTenantId(tenantId);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in fetching courses");
	}
});
