import React, { useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

import AllRoutes from "../../AllRoutes";
import { Button, ToggleButtonGroup } from "@mui/joy";
import BackButton from "../utils/BackButton";
import WithAuth from "../utils/WithAuth";
import { UserRole } from "../../Constants/user-role";
import { useSelector } from "react-redux";

const Home = () => {
	// useEffect(() => {
	//     navigate('/course')
	// }, [])

	return (
		<div>
			<AllRoutes />
		</div>
	);
};

export default Home;
