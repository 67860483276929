const colorObj = {
	COMPLETED: "#509b64",
	IN_PROGRESS: "#FFC237",
	PENDING: "#FF5C5C",
};
const bgColorObj = {
	COMPLETED: "#E1F7E7",
	IN_PROGRESS: "#FFF7E1",
	PENDING: "#FFECEC",
};

export { colorObj, bgColorObj };
