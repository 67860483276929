import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteBlob, fetchBlobs, uploadFile } from "../services/blobStorageServices";

export const fetchBlobsThunk = createAsyncThunk("blob/fetchBlobs", async (_, { rejectWithValue }) => {
	try {
		const response = await fetchBlobs();
		return response;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const uploadFileThunk = createAsyncThunk("blob/upoloadFile", async (files, { rejectWithValue }) => {
	try {
		const response = await uploadFile(files);
		return response;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const deleteBlobThunk = createAsyncThunk("blob/deleteBlob", async (name, { rejectWithValue }) => {
	try {
		const response = await deleteBlob(name);
		return response;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});
