import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const fetchVideoSummary = async (courseId) => {
	const config = {
		method: "post",
		url: "https://api.interleap.com/course_videos",
		headers: {
			"X-INTERLEAP-API-KEY": "9d4cb3f194a25b9413c9b1296d1b620f2c9ca19d",
			"Content-Type": "application/json",
		},
		data: { course_id: courseId },
	};
	const response = await axios(config);
	return response.data;
};

export const checkVideosStatus = async (courseId) => {
	const response = await axios.get(`${API_URL}/video/check-status?courseId=${courseId}`);
	return response.data;
};

export const fetchVideosByIds = async (videoIds) => {
	const response = await axios.post(`${API_URL}/video/video-by-id`, {
		video_ids: videoIds,
	});
	return response?.data?.data;
};

export const addManualVideo = async (video) => {
	const response = await axios.post(
		`${API_URL}/video/add-video`,
		{
			...video,
		},
		{
			headers: {
				Authorization: `Bearer ${fetchToken()}`,
			},
		}
	);
	return response;
};

export const deleteVideo = async (videoId, node_id) => {
	const response = await axios.delete(`${API_URL}/video?video_id=${videoId}&node_id=${node_id}`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response;
};

export const updateVideo = async (video) => {
	const response = await axios.patch(`${API_URL}/video/`, video, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response;
};
