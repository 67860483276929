import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dropdown, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VideoEditForm from "./edit-video/edit-video-form";
import { useLocation } from "react-router-dom";
import { deleteVideoThunk } from "../../../Store/actions/videoAction";
import { deleteVideo } from "../../../Store/slices/videoSlice";
import Loader from "../../utils/Loader";

const Video = ({ setIsModalOpen }) => {
	const dispatch = useDispatch();
	const { videoData, videoLoading, error } = useSelector((state) => state.video);

	const [showDeleteModalForVideo, setShowDeleteModalForVideo] = useState("");
	const [showVideoEditModal, setShowVideoEditModal] = useState("");
	const [loading, setLoading] = useState(false);

	const location = useLocation();

	const node_id = location.pathname.split("/")[4];

	const handleDelete = async (video_id) => {
		try {
			setLoading(true);
			const response = await dispatch(deleteVideoThunk({ video_id, node_id }));
			if (response?.payload.data) {
				setLoading(false);
				dispatch(deleteVideo(response.payload.data));
				setShowDeleteModalForVideo("");
			}
		} catch (error) {}
	};

	const renderDeleteModal = (video) => {
		return (
			<Modal
				open={showDeleteModalForVideo === video?.video_id}
				onClose={() => setShowDeleteModalForVideo("")}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to delete this video?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							color='primary'
							onClick={() => setShowDeleteModalForVideo("")}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							onClick={() => handleDelete(video?.video_id)}
							loading={loading}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderEditVideoModal = (video) => {
		return (
			<Modal
				open={showVideoEditModal === video?.video_id}
				onClose={() => setShowVideoEditModal("")}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose />
					<Typography level='h4'>{video?.title}</Typography>
					<VideoEditForm
						video={video}
						setOpen={setShowVideoEditModal}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderVideoCard = (video) => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					p: ".8rem 1rem",
					borderRadius: "6px",
					bgcolor: "#f4f4f4",
					my: 2,
					cursor: "pointer",
				}}
				onClick={() => {
					setShowVideoEditModal(video?.video_id);
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					<img
						src={`/_thumbnail.jpg`}
						alt={`Video thumbnail`}
						style={{
							width: "60px",
							height: "35px",
							margin: "0 10px",
						}}
					/>
					<Typography
						variant='body2'
						sx={{
							color: "#3838f1",
							fontWeight: 500,
							cursor: "pointer",
						}}
					>
						{video?.title || video?.url}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<Typography
						maxWidth={300}
						noWrap
						sx={{
							mr: 1,
							fontSize: "12px",
						}}
						level='body-md'
					>
						{video?.url}
					</Typography>
					<Typography
						sx={{ fontSize: "12px" }}
						level='body-md'
					>
						{video?.duration}hr
					</Typography>
					<Dropdown>
						<MenuButton
							sx={{
								backgroundColor: "transparent",
								border: "none",
								cursor: "pointer",
								padding: 0,
							}}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<MoreHorizIcon />
						</MenuButton>
						<Menu
							modifiers={[
								{
									name: "offset",
									options: {
										offset: ({ placement }) => {
											if (placement.includes("end")) {
												return [8, 20];
											}
											return [50, -20];
										},
									},
								},
							]}
						>
							<MenuItem
								onClick={(e) => {
									e.stopPropagation();
									setShowVideoEditModal(video?.video_id);
								}}
							>
								Edit
							</MenuItem>
							<MenuItem
								onClick={(e) => {
									e.stopPropagation();
									setShowDeleteModalForVideo(video?.video_id);
								}}
							>
								<Typography color='danger'>Delete</Typography>
							</MenuItem>
						</Menu>
					</Dropdown>
				</Box>
			</Box>
		);
	};

	const renderVideoList = () => {
		if (videoLoading) {
			return (
				<Box>
					<Loader />
				</Box>
			);
		} else if (error) {
			dispatch(showToast({ message: error, type: ToastType.ERROR }));
		} else if (videoData && videoData.length > 0) {
			return (
				<Box sx={{ maxHeight: "40vh", overflowY: "auto", my: 2 }}>
					<Box
						sx={{
							px: 2,
						}}
					>
						{videoData.map((video) => {
							return (
								<>
									{renderVideoCard(video)}
									{showDeleteModalForVideo && renderDeleteModal(video)}
									{showVideoEditModal && renderEditVideoModal(video)}
								</>
							);
						})}
					</Box>
				</Box>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Add Videos to the Topic for the students to watch.
					</Typography>
				</Box>
			);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "0 1rem",
				}}
			>
				<Box>
					<Typography
						level='h5'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Videos {videoData?.length > 0 && `${videoData?.length}`}
					</Typography>
				</Box>
				<Box>
					<Button
						sx={{ color: "#3838f1", fontWeight: "600", fontSize: "14px", bgcolor: "#f5f5ff", borderRadius: "4px" }}
						variant='primary'
						onClick={() => setIsModalOpen(true)}
					>
						Add Video
					</Button>
				</Box>
			</Box>
			<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.30)", my: 1 }}></Box>
			{renderVideoList()}
		</Box>
	);
};

export default Video;
