// Import necessary libraries
import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { addCourseData } from "../../Store/slices/courseSlice";
import { showToast } from "../../Store/slices/commonSlice";
import { ToastType } from "../../Constants/toast-type";

const ExcelReader = ({ setExcelData }) => {
	const predefinedCols = ["MODULE", "TOPICS"];
	const [error, setError] = useState("");
	const dispatch = useDispatch();
	const handleFileUpload = (event) => {
		let file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = (e) => {
			const binaryStr = e.target.result;
			const workbook = XLSX.read(binaryStr, { type: "binary" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet);
			const objectKeys = Object.keys(jsonData[0]);
			const arraysMatch = predefinedCols.every((col) => objectKeys.includes(col));
			setError("");
			if (!arraysMatch) {
				const missingCols = predefinedCols.filter((col) => !objectKeys.includes(col));

				dispatch(showToast({ toastMessage: `${missingCols?.join(" , ")} are missing`, toastType: ToastType.ERROR }));
				setError(`${missingCols?.join(" , ")} are missing`);
				setExcelData([]);
				return;
			}

			const modules = {}; // Initialize an empty object to store modules by topic
			const processedData = [];
			// Initialize an empty array to store the final processed data

			jsonData.forEach((item) => {
				// Check if item is defined and has required properties
				if (item && item["TOPICS"]) {
					const topic = item["TOPICS"];
					const videoLinks = item["VIDEO LINKS"] ? item["VIDEO LINKS"].trim() : null;
					const videoTitle = item["VIDEO_TITLE"] ? item["VIDEO_TITLE"].trim() : null;
					const videoHours = item["VIDEO_HOURS"] ? item["VIDEO_HOURS"] : 0;
					const points = item["POINTS"] ? item["POINTS"] : 0;

					if (videoLinks) {
						const videoData = {
							video_url: videoLinks,
							video_title: videoTitle,
							video_hours: videoHours,
							points: points,
						};

						// If the topic already exists in the modules, push the new video data
						if (modules[topic]) {
							modules[topic].video_urls.push(videoData);
							modules[topic].POINTS += points;
							modules[topic].VIDEO_HOURS += videoHours;
						} else {
							// Otherwise, create a new entry for this topic in modules and push it to processedData
							modules[topic] = {
								...item,
								video_urls: [videoData],
							};
							processedData.push(modules[topic]);
						}
					} else {
						if (!modules[topic]) {
							modules[topic] = {
								...item,
								video_urls: null,
							};
							processedData.push(modules[topic]);
						}
					}
				}
			});
			setExcelData(processedData);
		};

		reader.readAsBinaryString(file);
	};

	return (
		<>
			<div className='file-upload-container'>
				<input
					type='file'
					accept='.xlsx, .xls'
					onChange={handleFileUpload}
				/>

				{/* <table>
                <thead>
                    <tr>
                        {data.length > 0 &&
                            Object.keys(data[0]).map((key) => <th key={key}>{key}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            {Object.values(row).map((val, i) => (
                                <td key={i}>{val}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table> */}
			</div>
			{error && <div style={{ color: "red", fontSize: ".8rem", fontWeight: "500", margin: ".5rem 0" }}>{error}</div>}
		</>
	);
};

export default ExcelReader;
