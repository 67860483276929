import { createAsyncThunk } from "@reduxjs/toolkit";
import { addModule, addTopic, fetchCourseDetails, fetchCourses, updateTopicDetails } from "../services/coursesApiService";

export const fetchCourseDetailsThunk = createAsyncThunk("courseDetails/fetchCourseDetails", async (obj) => {
	const data = await fetchCourseDetails(obj?.courseId, obj?.batchId);
	return data;
});

export const fetchAllCoursesThunk = createAsyncThunk("courseDetails/fetchAllCourses", async (query, { rejectWithValue }) => {
	try {
		const data = await fetchCourses(query);
		return data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while fetching courses.");
	}
});

export const addModuleThunk = createAsyncThunk("courseDetails/addModule", async ({ rootNodeId, moduleData }, { rejectWithValue }) => {
	try {
		const data = await addModule(rootNodeId, moduleData);
		return data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while adding module.");
	}
});

export const addTopicThunk = createAsyncThunk("courseDetails/addTopic", async ({ moduleNodeId, topicData }, { rejectWithValue }) => {
	try {
		const data = await addTopic(moduleNodeId, topicData);
		return data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while adding topic.");
	}
});

export const updateTopicThunk = createAsyncThunk("courseDetails/updateTopic", async (topicData, { rejectWithValue }) => {
	try {
		const data = await updateTopicDetails(topicData);
		return data;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while updating topic.");
	}
});
