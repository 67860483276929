import { Box, Button, Dropdown, Input, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Select, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserRole } from "../../../../../Constants/user-role";
import { ReactComponent as TeacherSvg } from "../../../../../Assets/teacher.svg";
import { getFirstLetterCapitalized } from "../../../../../helpers/text-formatter";
import AddTeacher from "./add-new-teacher";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch } from "react-redux";
import { fetchTeachersByTenant } from "../../../../../Store/actions/userAction";
import { unassignTeacherThunk } from "../../../../../Store/actions/authenticateAction";
import { fetchBatchByIdThunk } from "../../../../../Store/actions/batchAction";
import { addTeacherInfo, setFormFilled } from "../../../../../Store/slices/batchSlice";
import { batchType } from "../../../../../Constants/batch-tabs";

const TeacherDetails = ({ batchId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [unassignModal, setUnassignModal] = useState(false);

	const { batchDetails, teacherInfo, selectedTenant } = useSelector((state) => state.batch);
	const { user } = useSelector((state) => state.user);

	const dispatch = useDispatch();

	useEffect(() => {
		if (batchDetails?.data?.instructor_id) {
		} else {
			dispatch(fetchTeachersByTenant(user?.tenant_id || selectedTenant || batchDetails?.data?.courseDetails[0]?.tenant_id));
		}

		if (!batchId) {
			dispatch(setFormFilled({ tab: batchType.teacherDetails, filled: true }));
		}
	}, []);

	const renderCreateTeacherModal = () => {
		return (
			<AddTeacher
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				batchId={batchId}
			/>
		);
	};

	const handleOpenUnassignModal = (e) => {
		e.stopPropagation();
		setUnassignModal(true);
	};

	const handleUnassignTeacher = async () => {
		const teacherData = {
			batch_id: batchId || batchDetails?.data?.batch_id,
		};
		setLoading(true);
		const response = await dispatch(unassignTeacherThunk(teacherData));
		if (response.payload.data) {
			dispatch(addTeacherInfo(null));
			setUnassignModal(false);
			setLoading(false);
		}
	};

	const renderUnassignModal = () => {
		return (
			<Modal
				open={unassignModal}
				onClose={() => {
					setUnassignModal(false);
				}}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to unassign this teacher?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							variant='solid'
							color='primary'
							onClick={() => setUnassignModal(false)}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							onClick={handleUnassignTeacher}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderTeacherCard = (teacher) => {
		return (
			<Box
				sx={{
					backgroundColor: "#f4f4f4",
					borderRadius: "sm",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					p: ".8rem 1rem",
					my: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					<TeacherSvg
						style={{
							width: "20px",
							height: "20px",
						}}
					/>
					<Typography
						sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
						noWrap
					>
						{getFirstLetterCapitalized(teacher.name)}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Typography
						maxWidth={400}
						noWrap
						sx={{
							mr: 1,
						}}
					>
						{teacher.email}
					</Typography>
					{user?.role !== UserRole.TEACHER && (
						<Dropdown>
							<MenuButton
								sx={{
									backgroundColor: "transparent",
									border: "none",
									cursor: "pointer",
									padding: 0,
								}}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<MoreHorizIcon />
							</MenuButton>
							<Menu
								modifiers={[
									{
										name: "offset",
										options: {
											offset: ({ placement }) => {
												if (placement.includes("end")) {
													return [8, 20];
												}
												return [50, -20];
											},
										},
									},
								]}
							>
								<MenuItem
									color='danger'
									onClick={handleOpenUnassignModal}
								>
									Unassign Teacher
								</MenuItem>
							</Menu>
						</Dropdown>
					)}
				</Box>
			</Box>
		);
	};

	const renderTeachers = () => {
		if (teacherInfo === null) {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						No teachers Found
					</Typography>
				</Box>
			);
		} else {
			return renderTeacherCard(teacherInfo);
		}
	};

	return (
		<Box
			sx={{
				display: "grid",
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography level='h5'>Teachers</Typography>
				{user?.role !== UserRole.TEACHER && !teacherInfo && (
					<Button
						variant='primary'
						onClick={() => setIsOpen(true)}
						sx={{ display: "flex", justifySelf: "flex-end", alignItems: "center", gap: 1, backgroundColor: "#3838F1" }}
					>
						<Typography
							sx={{ width: "fit-content", color: "white" }}
							level='body-sm'
						>
							Add a Teacher
						</Typography>
					</Button>
				)}
			</Box>
			<Box sx={{ borderBottom: "1px solid black", my: 1 }}></Box>
			{renderTeachers()}
			{unassignModal && user?.role !== UserRole.TEACHER && renderUnassignModal()}
			{user?.role !== UserRole.TEACHER && !teacherInfo && renderCreateTeacherModal()}
		</Box>
	);
};

export default TeacherDetails;
