import { createAsyncThunk } from "@reduxjs/toolkit";
import { addTopicSession, fetchTopicSessionByBatchId, updateTopicSession } from "../services/topicSessionService";

export const addTopicSessionThunk = createAsyncThunk("topicSession/addTopicSession", async (topicSession, { rejectWithValue }) => {
	try {
		const response = await addTopicSession(topicSession);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while adding topic session.");
	}
});

export const fetchTopicSessionByBatchIdThunk = createAsyncThunk("topicSession/fetchTopicSession", async (batchId, { rejectWithValue }) => {
	try {
		const response = await fetchTopicSessionByBatchId(batchId);
		return response;
	} catch (error) {
		return rejectWithValue(error.response.data.message || "An error occurred while fetching topic session.");
	}
});

export const updateTopicSessionThunk = createAsyncThunk("topicSession/updateTopicSession", async (topicSession, { rejectWithValue }) => {
	try {
		const response = await updateTopicSession(topicSession);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "An error occurred while updating topic session.");
	}
});
