import { createAsyncThunk } from "@reduxjs/toolkit";

import { addBatch, addStudentsAndAssignToBatch, deleteBatch, fetchBatchById, fetchBatches, fetchStudentsByBatchId, publishBatch, updateBatchById } from "../services/batchApiService";

export const fetchBatchesThunk = createAsyncThunk("batch/fetchBatches", async (query, { rejectWithValue }) => {
	try {
		const response = await fetchBatches(query);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in fetching batches");
	}
});

export const addBatchThunk = createAsyncThunk("batch/addBatch", async (batch, { rejectWithValue }) => {
	try {
		const response = await addBatch(batch);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in adding batch");
	}
});

export const fetchStudentsByBatchIdThunk = createAsyncThunk("batch/fetchStudentsByBatchId", async ({ batchId, query }, { rejectWithValue }) => {
	try {
		const response = await fetchStudentsByBatchId(batchId, query);
		return response;
	} catch (error) {
		return rejectWithValue(error.response.data.message || "Error in fetching students");
	}
});

export const addStudentsAndAssignToBatchThunk = createAsyncThunk("batch/addStudentsAndAssignToBatch", async (students, batchId, { rejectWithValue }) => {
	try {
		const response = await addStudentsAndAssignToBatch(students, batchId);
		return response;
	} catch (error) {
		return rejectWithValue(error.message || "Error in adding students to batch");
	}
});

export const fetchBatchByIdThunk = createAsyncThunk("batch/fetchBatchById", async (batchId, { rejectWithValue }) => {
	try {
		const response = await fetchBatchById(batchId);
		return response;
	} catch (error) {
		return rejectWithValue(error?.response?.data?.message || "Error in fetching batch");
	}
});

export const updateBatchByIdThunk = createAsyncThunk("batch/updateBatchById", async (batch) => {
	try {
		const response = await updateBatchById(batch);
		return response;
	} catch (error) {
		return error?.response?.data?.message || "Error in updating batch";
	}
});

export const deleteBatchThunk = createAsyncThunk("batch/deleteBatch", async (batchId) => {
	try {
		const response = await deleteBatch(batchId);
		return response;
	} catch (error) {
		return error?.response?.data?.message || "Error in deleting batch";
	}
});

export const publishBatchThunk = createAsyncThunk("batch/publishBatch", async (batchId) => {
	try {
		const response = await publishBatch(batchId);
		return response;
	} catch (error) {
		return error?.response?.data?.message || "Error in publishing batch";
	}
});
