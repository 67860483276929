import { Box, Button, Input, Typography } from "@mui/joy";
import React, { Suspense, useState } from "react";
import ReactPlayer from "react-player";
import Loader from "../../../utils/Loader";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateVideoThunk } from "../../../../Store/actions/videoAction";
import { updateVideo } from "../../../../Store/slices/videoSlice";

const VideoEditForm = ({ video, setOpen }) => {
	const [videoName, setVideoName] = useState(video?.title);
	const [videoUrl, setVideoUrl] = useState(video?.url);
	const [videoDuration, setVideoDuration] = useState(video?.duration);
	const [selectedPoint, setSelectedPoint] = useState(video?.points || 5);
	const [loading, setLoading] = useState(false);

	const location = useLocation();
	const dispatch = useDispatch();

	const node_id = location.pathname.split("/")[4];

	const handleSubmit = async () => {
		try {
			if (
				!videoName ||
				!videoUrl ||
				!videoDuration ||
				!selectedPoint ||
				(videoName?.trim() === video?.title?.trim() && videoUrl?.trim() === video?.url?.trim() && videoDuration === video.duration && selectedPoint === (video.points || 5))
			) {
				return;
			}

			const data = {
				title: videoName,
				url: videoUrl,
				duration: videoDuration,
				points: selectedPoint,
				video_id: video.video_id,
				node_id,
			};

			setLoading(true);
			const response = await dispatch(updateVideoThunk(data));

			if (response?.payload?.data) {
				setLoading(false);
				dispatch(updateVideo(response.payload.data));
				setOpen(false);
			}
		} catch (error) {}
	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Box>
						<Typography
							sx={{ fontSize: "12px" }}
							level='body-md'
						>
							{video?.url}
						</Typography>
					</Box>
					<Box>
						<Typography
							sx={{ fontSize: "12px" }}
							level='body-md'
						>
							{video?.duration}hrs
						</Typography>
					</Box>
					<Box>
						<Typography
							sx={{ fontSize: "12px" }}
							level='body-md'
						>
							{video?.points || 5}pts
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						borderRadius: "8px",
						padding: " 1rem",
						backgroundColor: "#f4f4f4",
						height: "200px",
					}}
				>
					{video?.url ? (
						<ReactPlayer
							url={video?.url}
							controls
							width='100%'
							height='100%'
							style={{ borderRadius: "8px", height: "100%", backgroundColor: "black" }}
						/>
					) : (
						<Box>No Video Found</Box>
					)}
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							maxHeight: "20vh",
							overflowY: "auto",
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								gap: 2,
							}}
						>
							<Box
								sx={{
									width: "50%",
								}}
							>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Video Name
								</Typography>
								<Input
									value={videoName}
									label='Video Name'
									type='text'
									sx={{ width: "100%" }}
									onChange={(e) => setVideoName(e.target.value)}
								/>
							</Box>
							<Box
								sx={{
									width: "50%",
								}}
							>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Video Duration
								</Typography>
								<Input
									value={videoDuration}
									label='Video Duration'
									type='time'
									slotProps={{
										input: {
											step: 1,
										},
									}}
									sx={{ width: "100%" }}
									onChange={(e) => setVideoDuration(e.target.value)}
								/>
							</Box>
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Video Url
							</Typography>
							<Input
								value={videoUrl}
								label='Video Name'
								type='text'
								sx={{ width: "100%" }}
								onChange={(e) => setVideoUrl(e.target.value)}
							/>
						</Box>
					</Box>

					<Box>
						<Typography
							level='h6'
							sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
						>
							Points
						</Typography>
						<Box sx={{ display: "flex", gap: 1 }}>
							{[5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
									sx={{
										bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
										color: `${selectedPoint === point ? "white" : "black"}`,
										"&:hover": {
											bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
											color: `${selectedPoint === point ? "white" : "black"}`,
										},
									}}
									onClick={() => setSelectedPoint(point)}
								>
									{point}
								</Button>
							))}
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button
							onClick={handleSubmit}
							loading={loading}
							disabled={
								loading ||
								!videoName ||
								!videoUrl ||
								!videoDuration ||
								!selectedPoint ||
								(videoName?.trim() === video?.title?.trim() && videoUrl?.trim() === video?.url?.trim() && videoDuration === video.duration && selectedPoint === (video.points || 5))
							}
						>
							Edit Video
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default VideoEditForm;
