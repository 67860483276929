// src/slices/index.js
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import courseReducer from "./slices/courseSlice";
import taskReducer from "./slices/taskDetailsSlice";
import blobStorage from "./slices/blobStorage";
import commonReducer from "./slices/commonSlice";
import videoReducer from "./slices/videoSlice";
import batchReducer from "./slices/batchSlice";
import topicReducer from "./slices/topicSessionSlice";
import batchEventReducer from "./slices/batchEventSlice";
import userReducer from "./slices/userSlice";
import referenceReducer from "./slices/referencesSlice";
import tenantReducer from "./slices/tenantSlice";

const persistConfig = {
	key: "root",
	storage,
};

const rootReducer = combineReducers({
	course: courseReducer,
	task: taskReducer,
	common: commonReducer,
	video: videoReducer,
	batch: batchReducer,
	topic: topicReducer,
	batchEvent: batchEventReducer,
	user: persistReducer(persistConfig, userReducer),
	blobStorage,
	references: referenceReducer,
	tenant: tenantReducer,
});

export default rootReducer;
