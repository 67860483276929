import { createSlice, current } from "@reduxjs/toolkit";
import { addManualMaterialThunk, fetchMaterialByNodeThunk } from "../actions/materialAction";

const initialState = {
	referenceData: [],
	referenceLoading: false,
	referenceError: null,
};

const referencesSlice = createSlice({
	name: "references",
	initialState,
	reducers: {
		addReference: (state, action) => {
			let currentState = current(state);
			state.referenceData = [...currentState.referenceData, ...action.payload];
		},
		clearReference: (state) => {
			state.referenceData = [];
		},
		deleteReference: (state, action) => {
			let currentState = current(state);
			state.referenceData = currentState.referenceData.filter((reference) => reference.material_id !== action.payload.material_id);
		},
		updateReference: (state, action) => {
			let currentState = current(state);
			state.referenceData = currentState.referenceData.map((reference) => {
				if (reference.material_id === action.payload.material_id) {
					return {
						...reference,
						...action.payload,
					};
				}
				return reference;
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchMaterialByNodeThunk.pending, (state) => {
				state.referenceLoading = true;
				state.referenceError = null;
			})
			.addCase(fetchMaterialByNodeThunk.fulfilled, (state, action) => {
				state.referenceLoading = false;
				state.referenceData = action.payload?.data;
				state.referenceError = null;
			})
			.addCase(fetchMaterialByNodeThunk.rejected, (state, action) => {
				state.referenceLoading = false;
				state.referenceError = action.payload;
			});
	},
});

export const { addReference, deleteReference, clearReference, updateReference } = referencesSlice.actions;
export default referencesSlice.reducer;
