import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
import AllRoutes from "./AllRoutes";
import { ReactComponent as CompanyLogo } from "./Assets/Logo.svg";
import Toast from "./Components/utils/Toast";

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route
						path='/*'
						element={<Home />}
					/>
				</Routes>
			</BrowserRouter>
			<Toast />
		</div>
	);
}

export default App;
