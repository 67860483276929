export const getTopicInfoFromNode = (courseData, topicId) => {
	let moduleInfo = {};

	// Helper function to recursively find the topic node and its parent
	const findTopicNode = (nodes, topicId) => {
		for (const node of nodes) {
			if (node.node_id === parseInt(topicId)) {
				return { topicNode: node, parentNode: node.parent_node_id };
			} else if (node.children?.length > 0) {
				const result = findTopicNode(node.children, topicId, node);
				if (result) {
					return result;
				}
			}
		}
		return null;
	};

	const result = findTopicNode(courseData?.children, topicId);
	if (result) {
		const { topicNode } = result;

		if (topicNode) {
			moduleInfo = topicNode;
		}
	}
	return moduleInfo;
};

export const getTopicInfoByVideoIdFromNodes = (courseData, videoId) => {
	let moduleInfo = {};

	// Helper function to recursively find the topic node and its parent
	const findTopicNode = (nodes, videoId) => {
		for (const node of nodes) {
			if (node.videos?.includes(videoId)) {
				return { topicNode: node, parentNode: node.parent_node_id };
			} else if (node.children?.length > 0) {
				const result = findTopicNode(node.children, videoId, node);
				if (result) {
					return result;
				}
			}
		}
		return null;
	};

	const result = findTopicNode(courseData?.children, videoId);
	if (result) {
		const { topicNode } = result;

		if (topicNode) {
			moduleInfo = topicNode;
		}
	}
	return moduleInfo;
};

export const checkAllNodesInTopicSessions = (nodes, topicSessions) => {
	// Check if topicSessions is defined and not empty
	if (!topicSessions || topicSessions.length === 0) return false;

	// Check if every node is present in topicSessions
	const allNodesPresent = nodes?.every((node) => {
		// Return false if the node_id is not found in topicSessions
		//skip the check if the node_type is MODULE
		if (node.node_type === "MODULE") return true;
		return topicSessions.some((session) => session.node_id === node.node_id);
	});

	return allNodesPresent; // Returns true only if all nodes are present in topicSessions
};

export const getAllTopics = (courses) => {
	let topics = [];

	const extractLeafNodes = (nodes) => {
		for (const node of nodes) {
			if (node?.children?.length > 0) {
				// Recursively extract leaf nodes from children
				extractLeafNodes(node.children);
			} else {
				// If the node has no children, it's a leaf node
				topics.push(node);
			}
		}
	};

	// Start extraction from the root level
	if (Array.isArray(courses)) {
		extractLeafNodes(courses);
	} else if (courses) {
		extractLeafNodes([courses]); // Handle the case where a single course object is passed
	}

	return topics;
};
