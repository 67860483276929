import { createSlice, current } from "@reduxjs/toolkit";
import { fetchTopicSessionByBatchIdThunk } from "../actions/topicSessionAction";

const initialState = {
	topicSessions: [],
	loading: false,
	error: null,
};

const topicSessionSlice = createSlice({
	name: "topicSession",
	initialState,
	reducers: {
		setTopicSessions(state, action) {
			let currentState = current(state);
			const newTopicSession = action.payload;
			state.topicSessions = [...currentState.topicSessions, ...newTopicSession];
		},
		updateTopicSession(state, action) {
			let currentState = current(state);
			state.topicSessions = currentState.topicSessions.map((topicSession) => (topicSession.topic_session_id === action.payload.topic_session_id ? action.payload : topicSession));
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTopicSessionByBatchIdThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchTopicSessionByBatchIdThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.topicSessions = action.payload?.data;
			})
			.addCase(fetchTopicSessionByBatchIdThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { setTopicSessions, updateTopicSession } = topicSessionSlice.actions;
export default topicSessionSlice.reducer;
