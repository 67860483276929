import { Box, Input, Typography } from "@mui/joy";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { convertToInputDate } from "../../../../helpers/dateTime";
import { UserRole } from "../../../../Constants/user-role";
import { useDispatch } from "react-redux";
import { updateBatchByIdThunk } from "../../../../Store/actions/batchAction";
import { DateTime } from "luxon";
import Loader from "../../../utils/Loader";

const BatchDetails = () => {
	const { batchDetails, batchLoading } = useSelector((state) => state.batch);
	const { user } = useSelector((state) => state.user);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const dispatch = useDispatch();
	const handleSubmit = async () => {
		const format = "d/M/yyyy";
		if (startDate && endDate) {
			const utcStartDate = DateTime.fromISO(startDate, format).toUTC().toISO();
			const utcEndDate = DateTime.fromISO(endDate, format).toUTC().toISO();
			dispatch(updateBatchByIdThunk({ batch_id: batchDetails?.data?.batch_id, start_date: utcStartDate, end_date: utcEndDate }));
		}
		if (startDate) {
			const utcStartDate = DateTime.fromISO(startDate, format).toUTC().toISO();

			dispatch(updateBatchByIdThunk({ batch_id: batchDetails?.data?.batch_id, start_date: utcStartDate }));
		}
		if (endDate) {
			const utcEndDate = DateTime.fromISO(endDate, format).toUTC().toISO();
			dispatch(updateBatchByIdThunk({ batch_id: batchDetails?.data?.batch_id, end_date: utcEndDate }));
		}
	};

	if (batchLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Loader />
			</Box>
		);
	}

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			{batchDetails?.data?.external_batch_id && (
				<Box
					sx={{
						my: 2,
					}}
				>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>External Batch Id</Typography>
					<Input
						value={batchDetails?.data?.external_batch_id}
						placeholder='Enter External batch id'
						readOnly
						disabled
					/>
				</Box>
			)}
			<Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Batch Name</Typography>
					<Input
						value={batchDetails?.data?.name || ""}
						placeholder='Enter Batch Name'
						readOnly={user?.role === UserRole.TEACHER}
						disabled={user?.role === UserRole.TEACHER}
					/>
				</Box>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Name of the course</Typography>
					<Input
						value={batchDetails?.data?.courseDetails[0]?.name}
						readOnly={user?.role === UserRole.TEACHER}
						disabled={user?.role === UserRole.TEACHER}
					/>
				</Box>
			</Box>
			<Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Start Date</Typography>
					<Input
						type='date'
						readOnly={user?.role === UserRole.TEACHER}
						disabled={user?.role === UserRole.TEACHER}
						onChange={(e) => setStartDate(e.target.value)}
						onClick={handleSubmit}
						value={convertToInputDate(batchDetails?.data?.start_date) || ""}
					/>
				</Box>
				<Box sx={{ width: "50%", mx: 1 }}>
					<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>End date</Typography>
					<Input
						type='date'
						readOnly={user?.role === UserRole.TEACHER}
						disabled={user?.role === UserRole.TEACHER}
						onChange={(e) => setEndDate(e.target.value)}
						onClick={handleSubmit}
						value={endDate || convertToInputDate(batchDetails?.data?.end_date) || ""}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default BatchDetails;
