import React, { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { AccordionGroup, Accordion, AccordionDetails, AccordionSummary, Stack, Typography, FormLabel, ListItemContent, Box, IconButton } from "@mui/joy";
import MenuIcon from "@mui/icons-material/Menu";
import CreateTask from "../AddCourse/CreateTask";
import { useDispatch, useSelector } from "react-redux";
import { fetchVideosByIdsThunk } from "../../Store/actions/videoAction";
import { LinearProgress } from "@mui/material";
import { bgColorObj, colorObj } from "../../helpers/styleConfig";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function AccordionUtils({ courseData }) {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [selectedNode, setSelectedNode] = React.useState(null);
	const { courseStatus, statusLoader } = useSelector((state) => state.course);

	const navigate = useNavigate();

	const handleClose = () => {
		setOpen(false);
		setSelectedNode(null);
	};

	const handleOpen = (node) => {
		setOpen(true);
		setSelectedNode(node);
		if (node.videos && node.videos.length > 0) {
			dispatch(fetchVideosByIdsThunk(node.videos));
		}
	};

	const renderLeafNode = (node, idx) => (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				width: "95%",
				paddingX: "1rem",
				paddingY: "0.2rem",
				cursor: "pointer",
				mb: 1,
				":hover": {
					backgroundColor: "#f5f5f5",
					borderRadius: "5px",
				},
			}}
			key={node.node_id}
			onClick={() => navigate(`topic/${node?.node_id}`)}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					width: "100%",
					justifyContent: "space-between",
					cursor: "pointer",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<MenuIcon />
					<ListItemContent sx={{ marginLeft: 2 }}>
						<FormLabel
							sx={{
								fontFamily: "CustomFont, sans-serif",
								cursor: "pointer",
							}}
						>
							<Typography level='title-md'>{node.node_name}</Typography>
						</FormLabel>
						<Typography
							level='title-sm'
							sx={{ opacity: 0.5 }}
						>
							{node?.description}
						</Typography>
					</ListItemContent>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					{statusLoader ? (
						<LinearProgress
							color='inherit'
							sx={{ height: "1rem", width: "5rem", borderRadius: "20px" }}
						/>
					) : (
						<Typography
							sx={{
								color: colorObj[courseStatus?.data[node.node_id]?.status],
								backgroundColor: bgColorObj[courseStatus?.data[node.node_id]?.status],
								paddingY: "5px",
								paddingX: "10px",
								borderRadius: "10px",
							}}
						>
							{courseStatus?.data[node.node_id]?.status}
						</Typography>
					)}
					{/* <IconButton onClick={() => navigate(`topic/${node?.node_id}`)}>
						<OpenInNewIcon fontSize='12px' />
					</IconButton> */}

					<Typography
						sx={{ color: "GrayText" }}
						level='title-sm'
					>
						{node?.tasks?.length || 0} Task
					</Typography>
					<Typography
						sx={{ color: "GrayText" }}
						level='title-sm'
					>
						{node?.videos?.length || 0} Videos ({node.duration || "0 min"})
					</Typography>
					<Typography
						sx={{ color: "GrayText" }}
						level='title-sm'
					>
						{node.points || 0} pts
					</Typography>
				</Box>
			</Box>
		</Box>
	);

	const renderNode = (node, idx) => {
		if (node.children && node.children.length > 0) {
			return (
				<Accordion key={node.node_id}>
					<AccordionSummary>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<MenuIcon />
								<ListItemContent sx={{ marginLeft: 2 }}>
									<Typography level='title-md'>{node.node_name}</Typography>
									<Typography level='title-sm'>{node?.description}</Typography>
								</ListItemContent>
							</Box>
							<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
								<Typography
									sx={{ color: "GrayText" }}
									level='title-sm'
								>
									{node?.points}pts
								</Typography>
								<Typography
									sx={{ color: "GrayText" }}
									level='title-sm'
								>
									{node?.duration || "5hr"}
								</Typography>
							</Box>
						</Box>
					</AccordionSummary>
					<AccordionDetails>{node.children.map((childNode, childIdx) => renderNode(childNode, childIdx))}</AccordionDetails>
				</Accordion>
			);
		} else {
			return renderLeafNode(node, idx);
		}
	};

	return (
		<>
			<AccordionGroup
				variant='plain'
				transition='0.2s'
				sx={{
					maxWidth: "100%",
					borderRadius: "md",
					[`& .MuiAccordionDetails-content.MuiAccordionDetails-expanded`]: {
						paddingBlock: "1rem",
					},
					[`& .MuiAccordionSummary-button`]: {
						paddingBlock: "1rem",
					},
				}}
			>
				{courseData?.children?.map((section, index) => renderNode(section, index))}
			</AccordionGroup>
			<CreateTask
				open={open}
				handleOpen={handleOpen}
				handleClose={handleClose}
				topicId={selectedNode?.node_id}
			/>
		</>
	);
}
