import { BlobServiceClient } from "@azure/storage-blob";

const accountName = process.env.REACT_APP_ACCOUNT_NAME;
const sasToken = process.env.REACT_APP_SAS_TOKEN;
const containerName = process.env.REACT_APP_CONTAINER_NAME;

const blobServiceClient = new BlobServiceClient(
  `https://${accountName}.blob.core.windows.net${sasToken}`
);
const containerClient = blobServiceClient.getContainerClient(containerName);

export const fetchBlobs = async () => {
  const blobItems = containerClient.listBlobsFlat();
  const urls = [];
  for await (const blob of blobItems) {
    const tempBlockBlobClient = containerClient.getBlockBlobClient(blob.name);
    urls.push({ name: blob.name, url: tempBlockBlobClient.url });
  }
  return urls;
};


export const uploadFile = async (files) => {
  const uploadedFiles = await uploadFilesToAzure(files);
  return uploadedFiles;
};

const uploadFilesToAzure = async (filesToUpload) => {
  const uploadedFiles = [];
  for (const file of filesToUpload) {
    const blobName = `${file.name}`;
    const blobClient = containerClient.getBlockBlobClient(blobName);
    await blobClient.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });
    
    const blobUrl = blobClient.url;
    uploadedFiles.push({ name: blobName, url: blobUrl });
  }
  return uploadedFiles;
};

export const deleteBlob = async (blobName) => {
  const blobClient = containerClient.getBlockBlobClient(blobName);
  await blobClient.delete();
  return blobName;
};