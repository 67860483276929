import { Box, Button, Dropdown, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import PdfReferencesUploadModal from "./create-reference/pdf-upload-form";
import { useSelector } from "react-redux";
import Loader from "../../utils/Loader";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as AnchorImage } from "../../../Assets/anchor.svg";
import { useDispatch } from "react-redux";
import { deleteMaterialThunk, fetchMaterialByNodeThunk } from "../../../Store/actions/materialAction";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { deleteReference } from "../../../Store/slices/referencesSlice";
import ReferenceEditForm from "./edit-reference/edit-reference-form";

const References = () => {
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showReferenceDeleteModal, setShowReferenceDeleteModal] = useState("");
	const [showReferenceEditModal, setShowReferenceEditModal] = useState("");

	const dispatch = useDispatch();
	const location = useLocation();

	const node_id = location.pathname.split("/")[4];

	const { referenceData, referencesLoading } = useSelector((state) => state.references);

	const handleDelete = async (material_id) => {
		try {
			setLoading(true);
			const response = await dispatch(deleteMaterialThunk({ material_id, node_id }));
			if (response?.payload.data) {
				setLoading(false);
				dispatch(deleteReference(response.payload.data));
				setShowReferenceDeleteModal("");
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const renderEditModal = (material) => {
		return (
			<Modal
				open={showReferenceEditModal === material.material_id}
				onClose={() => setShowReferenceEditModal("")}
			>
				<ModalDialog
					sx={{
						width: 800,
					}}
				>
					<ModalClose />
					<Typography level='h4'>Edit Reference</Typography>
					<ReferenceEditForm
						material={material}
						setShowReferenceEditModal={setShowReferenceEditModal}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderDeleteModal = (material) => {
		return (
			<Modal
				open={showReferenceDeleteModal === material.material_id}
				onClose={() => setShowReferenceDeleteModal("")}
			>
				<ModalDialog>
					<ModalClose />
					<Typography level='h4'>Confirmation</Typography>
					<Typography level='h6'>Are you sure you want to delete this reference material?</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							color='primary'
							onClick={() => setShowReferenceDeleteModal("")}
						>
							No
						</Button>
						<Button
							variant='outlined'
							color='danger'
							onClick={() => handleDelete(material.material_id)}
							loading={loading}
						>
							Yes
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderReferenceCard = (reference) => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					p: ".8rem 1rem",
					borderRadius: "6px",
					bgcolor: "#f4f4f4",
					my: 2,
				}}
				onClick={() => {
					setShowReferenceEditModal(reference.material_id);
				}}
			>
				<Box>
					<Link
						to={reference.url}
						target='_blank'
						rel='noreferrer'
						onClick={(e) => e.stopPropagation()}
						style={{ textDecoration: "none", color: "black" }}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
								cursor: "pointer",
							}}
						>
							<AnchorImage />
							<Typography
								sx={{
									color: "#3838f1",
									fontWeight: 500,
									cursor: "pointer",
								}}
							>
								{reference.name || "Reference"}
							</Typography>
						</Box>
					</Link>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<Link
						to={reference.url}
						target='_blank'
						rel='noreferrer'
						onClick={(e) => e.stopPropagation()}
						style={{ textDecoration: "none", color: "black" }}
					>
						<Typography
							maxWidth={400}
							noWrap
							sx={{
								mr: 1,
							}}
						>
							{reference?.url}
						</Typography>
					</Link>
					<Dropdown>
						<MenuButton
							sx={{
								backgroundColor: "transparent",
								border: "none",
								cursor: "pointer",
								padding: 0,
							}}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<MoreHorizIcon />
						</MenuButton>
						<Menu
							modifiers={[
								{
									name: "offset",
									options: {
										offset: ({ placement }) => {
											if (placement.includes("end")) {
												return [8, 20];
											}
											return [50, -20];
										},
									},
								},
							]}
						>
							<MenuItem
								onClick={(e) => {
									e.stopPropagation();
									setShowReferenceEditModal(reference.material_id);
								}}
							>
								Edit
							</MenuItem>
							<MenuItem
								onClick={(e) => {
									e.stopPropagation();
									setShowReferenceDeleteModal(reference.material_id);
								}}
							>
								<Typography color='danger'>Delete</Typography>
							</MenuItem>
						</Menu>
					</Dropdown>
				</Box>
			</Box>
		);
	};

	const renderReferences = () => {
		if (referencesLoading) {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Loader />
				</Box>
			);
		} else if (referenceData?.length === 0) {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						No References Found
					</Typography>
				</Box>
			);
		} else {
			return (
				<Box sx={{ maxHeight: "40vh", overflowY: "auto", borderRadius: "8px", my: 2, px: 2 }}>
					{referenceData?.map((reference, index) => {
						return (
							<>
								{renderReferenceCard(reference)}
								{showReferenceDeleteModal === reference.material_id && renderDeleteModal(reference)}
								{showReferenceEditModal === reference.material_id && renderEditModal(reference)}
							</>
						);
					})}
				</Box>
			);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "0 1rem",
				}}
			>
				<Box>
					<Typography
						level='h5'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						References {referenceData?.length > 0 && `${referenceData?.length}`}
					</Typography>
				</Box>
				<Box>
					<Button
						sx={{ color: "#3838f1", fontWeight: "600", fontSize: "14px", bgcolor: "#f5f5ff", borderRadius: "4px" }}
						variant='primary'
						onClick={() => setOpenModal(true)}
					>
						Add Reference Manually
					</Button>
				</Box>
			</Box>
			<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.30)", my: 1 }}></Box>
			{renderReferences()}
			<PdfReferencesUploadModal
				open={openModal}
				setOpen={setOpenModal}
			/>
		</Box>
	);
};

export default References;
