import React from "react";
import "./utils.css";
const Card = ({ title, description, selected, onClick, disabled = false }) => {
	return (
		<div
			className='utlis-card'
			style={selected ? { border: "2px solid #000" } : { cursor: disabled ? "not-allowed" : "pointer", opacity: disabled ? 0.5 : 0.8 }}
			onClick={onClick}
		>
			<div className='utlis-title'>{title}</div>
			<div className='utlis-description'>{description}</div>
		</div>
	);
};

export default Card;

function StepProgress({ currentStep, steps }) {
	return (
		<div className='step-progress'>
			{steps?.map((_, index) => (
				<div
					key={index}
					className={`step ${index + 1 < currentStep ? "completed" : ""} ${index + 1 === currentStep ? "active" : ""} `}
				>
					{`0${index + 1}.`} {steps[index]?.text}
				</div>
			))}
		</div>
	);
}

export { StepProgress };
