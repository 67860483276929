import { Box, Button, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react";
import MCQOptions from "../create-task/mcq-option";
import { useDispatch } from "react-redux";
import { updateTask } from "../../../../Store/services/taskApiServices";
import { updateTaskData } from "../../../../Store/slices/taskDetailsSlice";
import { updateTaskThunk } from "../../../../Store/actions/taskAction";
import { useLocation } from "react-router-dom";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CodeHighlighter from "../../../utils/code-highlighter";
import { optionKey } from "../../../../Constants/option";
import { ToastType } from "../../../../Constants/toast-type";
import { showToast } from "../../../../Store/slices/commonSlice";
// import { updateTask } from "../../../../Store/slices/taskDetailsSlice";

const MCQEditForm = ({ setOpen, task }) => {
	const [question, setQuestion] = useState(task?.question);
	const [options, setOptions] = useState(task?.mcq_options);
	const [loading, setLoading] = useState(false);
	const [selectedPoint, setSelectedPoint] = useState(task?.points);
	const [steps, setSteps] = useState(1);

	const dispatch = useDispatch();
	const location = useLocation();
	const node_id = location.pathname.split("/")[4];

	const addCurrentOption = (index, value) => {
		setOptions((prev) =>
			prev.map((option, i) => {
				if (i === index) {
					return { ...option, text: value };
				}
				return option;
			})
		);
	};

	const handleCorrectChange = (index) => {
		//add the check for to add only one correct option

		setOptions((prevOptions) =>
			prevOptions.map((option, i) => {
				if (i === index) {
					const checkCorrectOption = prevOptions.some((option) => option.correct === true);
					if (checkCorrectOption) {
						if (option.correct) {
							return { ...option, correct: !option.correct };
						} else {
							dispatch(showToast({ toastMessage: "Only one correct option is allowed", toastType: ToastType.ERROR }));
							return option;
						}
					} else {
						return { ...option, correct: !option.correct };
					}
				}
				return option;
			})
		);
	};

	const handleDeleteOption = (index) => {
		setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
	};

	const handleSubmit = async () => {
		if (
			question.trim() === "" ||
			options.length < 2 ||
			options.some((option) => option.text.trim() === "") ||
			(question?.trim() === task?.question?.trim() && selectedPoint === task?.points && JSON.stringify(options) === JSON.stringify(task?.options))
		) {
			return;
		}

		const optionTexts = options.map((option) => option.text.trim());
		const uniqueOptions = new Set(optionTexts);
		if (optionTexts.length !== uniqueOptions.size) {
			dispatch(showToast({ toastMessage: "Options should be unique", toastType: ToastType.ERROR }));
			return;
		}

		const taskData = {
			question,
			mcq_options: options,
			points: selectedPoint,
			task_id: task?.task_id,
			node_id: node_id,
		};

		setLoading(true);
		const response = await dispatch(updateTaskThunk(taskData));
		if (response?.payload?.data) {
			dispatch(updateTaskData(response.payload.data));
			setLoading(false);
			setOpen(false);
		}
	};

	const renderEditForm = () => {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
						}}
					>
						<Box
							sx={{
								maxHeight: "40vh",
								overflowY: "auto",
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							<Box>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Enter the question :
								</Typography>
								<Textarea
									value={question}
									label='Question'
									type='text'
									minRows={5}
									maxRows={10}
									required
									sx={{ width: "100%" }}
									onChange={(e) => setQuestion(e.target.value)}
									placeholder='e.g. What are java collections?'
									error={question.trim() === "" ? "Please enter the question" : ""}
								/>
							</Box>
							<Box>
								<Typography
									level='h6'
									sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
								>
									Enter the options:
								</Typography>
								{options.map((option, index) => {
									return (
										<Box
											key={index}
											sx={{
												display: "flex",
												alignItems: "center",
												gap: 2,
												my: 2,
											}}
										>
											<TextField
												label={`Option ${index + 1}`}
												type='text'
												value={option.text}
												onChange={(e) => addCurrentOption(index, e.target.value)}
												sx={{ flexGrow: 1 }}
												maxRows={1}
												inputProps={{
													maxLength: 64,
												}}
												size='small'
												required
											/>
											<ToggleButtonGroup
												sx={{
													borderRadius: "12px",
													width: "40%",
												}}
												size='small'
											>
												<ToggleButton
													sx={{
														borderRight: option.correct ? "1px rgba(0, 0, 0, 0.10);" : "none",
														bgcolor: option.correct ? "#42B25B" : "#F5F5FF",
														color: option.correct ? "#F5F5FF" : "#000",
														fontWeight: "600",
														width: "50%",
														":hover": {
															bgcolor: option.correct ? "#42B25B" : "white",
															color: option.correct ? "#F5F5FF" : "black",
														},
													}}
													onClick={() => handleCorrectChange(index, true)}
												>
													Right
												</ToggleButton>
												<ToggleButton
													sx={{
														bgcolor: option.correct ? "#F5F5FF" : "#D55442",
														color: option.correct ? "#000" : "#F5F5FF",
														fontWeight: "600",
														width: "50%",
														":hover": {
															bgcolor: option.correct ? "#white" : "#D55442",
															color: option.correct ? "black" : "#F5F5FF",
														},
													}}
													onClick={() => handleCorrectChange(index, false)}
												>
													Wrong
												</ToggleButton>
											</ToggleButtonGroup>
										</Box>
									);
								})}
							</Box>
						</Box>
						<Box>
							<Typography
								level='h6'
								sx={{ mb: 1, color: "#050519", fontSize: "14px", fontWeight: "600" }}
							>
								Points
							</Typography>
							<Box sx={{ display: "flex", gap: 1 }}>
								{[1, 10, 15, 20, 25].map((point) => (
									<Button
										key={point}
										variant='outlined'
										color='neutral'
										sx={{
											bgcolor: `${selectedPoint === point ? "#000" : "white"}`,
											color: `${selectedPoint === point ? "white" : "black"}`,
											"&:hover": {
												bgcolor: `${selectedPoint === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
												color: `${selectedPoint === point ? "white" : "black"}`,
											},
										}}
										onClick={() => setSelectedPoint(point)}
									>
										{point}
									</Button>
								))}
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							mt: 2,
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setSteps(1);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Previous: View Task
						</Button>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 2,
							}}
						>
							<Button
								variant='outlined'
								onClick={() => {
									setOpen(false);
								}}
								sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
							>
								Cancel
							</Button>
							<Button
								sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
								color='primary'
								disabled={
									!question ||
									options.length === 0 ||
									loading ||
									!options.some((option) => option.correct === true) ||
									(question?.trim() === task?.question?.trim() && // Check if the question hasn't changed
										selectedPoint === task?.points && // Check if the points haven't changed
										JSON.stringify(options) === JSON.stringify(task?.mcq_options)) // Check if the options haven't changed
								}
								onClick={handleSubmit}
								loading={loading}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderInfoView = () => {
		return (
			<Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						padding: "1rem",
						maxHeight: "70vh",
						overflowY: "auto",
					}}
				>
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Question
					</Typography>
					<Box sx={{ borderRadius: "8px", mb: 1 }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700, px: 2 }}>{task?.question}</Typography>
					</Box>

					{task?.image_url && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
							<img
								src={task.image_url}
								alt='Preview'
								style={{ width: "100%", borderRadius: "10px", height: "100%", zIndex: 1000, objectFit: "contain" }}
							/>
						</Box>
					)}
					{task?.code_snippet && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
							<CodeHighlighter
								code={task?.code_snippet}
								height={"200px"}
							/>
						</Box>
					)}

					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Options
					</Typography>
					<Box sx={{ borderRadius: "8px" }}>
						{options.map((option, index) => {
							return (
								<Box
									key={index}
									sx={{
										mb: 0.5,
									}}
								>
									<Typography
										sx={{
											borderRadius: "5px",
											fontWeight: 700,
											color: option.correct ? "#42B25B" : "#050519",

											width: "fit-content",
											px: 2,
											fontSize: "1rem",
										}}
									>
										{optionKey[index + 1]}. &nbsp; {option.text}
									</Typography>
								</Box>
							);
						})}
					</Box>
					<Typography
						level='h6'
						sx={{ color: "#6E6E6F", fontSize: "14px", fontWeight: "600" }}
					>
						Points
					</Typography>
					<Box sx={{ borderRadius: "8px" }}>
						<Typography sx={{ color: "#050519", fontSize: "1rem", fontWeight: 700, px: 2 }}>{task?.points}</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						width: "100%",
						mt: 2,
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}
					>
						<Button
							variant='outlined'
							onClick={() => {
								setOpen(false);
							}}
							sx={{ bgcolor: "white", mb: 1, color: "#3838f1", border: "1px solid #f4f4f4" }}
						>
							Cancel
						</Button>
						<Button
							sx={{ mb: 1, bgcolor: "#3838f1", color: "white" }}
							color='primary'
							onClick={() => setSteps(2)}
						>
							Next: Edit Task
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderEditFormStep = () => {
		switch (steps) {
			case 1:
				return renderInfoView();
			case 2:
				return renderEditForm();
			default:
				return;
		}
	};

	return <Box>{renderEditFormStep()}</Box>;
};

export default MCQEditForm;
