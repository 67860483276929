import { createSlice, current } from "@reduxjs/toolkit";
import { fetchAllCoursesThunk, fetchCourseDetailsThunk } from "../actions/courseAction";
import { checkStatusThunk } from "../actions/videoAction";

const initialState = {
	allCourses: [],
	courseData: null,
	loading: false,
	error: {},
	courseStatus: null,
	statusLoader: false,
	statusError: null,
};

const courseDetailsSlice = createSlice({
	name: "courseDetails",
	initialState,
	reducers: {
		addCourseData: (state, action) => {
			state.courseData = action.payload;
		},
		addModuleData: (state, action) => {
			let currentCourseData = current(state?.courseData);
			const { moduleData } = action.payload;
			if (currentCourseData?.data?.children?.length === 0) {
				state.courseData = {
					...currentCourseData,
					data: {
						...currentCourseData.data,
						children: [moduleData.data],
					},
				};
				return;
			}
			const updatedChildren = [...currentCourseData.data.children, moduleData.data];

			state.courseData = {
				...currentCourseData,
				data: {
					...currentCourseData.data,
					children: updatedChildren,
				},
			};
		},

		updateNodeData: (state, action) => {
			let currentCourseData = current(state?.courseData);

			const updatedChildren = currentCourseData.data.children.map((child) => {
				if (child.node_id === action.payload.node_id) {
					return action.payload;
				}
				return child;
			});

			state.courseData = {
				...currentCourseData,
				data: {
					...currentCourseData.data,
					children: updatedChildren,
				},
			};
		},
	},
	extraReducers: (builder) => {
		// Fetch all courses thunk
		builder
			.addCase(fetchAllCoursesThunk.pending, (state) => {
				state.loading = true;
				state.courseData = null;
				state.error["fetchAllCourses"] = null;
			})
			.addCase(fetchAllCoursesThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.allCourses = action.payload;
				state.error["fetchAllCourses"] = null;
			})
			.addCase(fetchAllCoursesThunk.rejected, (state, action) => {
				state.loading = false;
				state.courseData = null;
				state.error["fetchAllCourses"] = action.error.message;
			});

		builder
			.addCase(checkStatusThunk.pending, (state) => {
				state.statusLoader = true;
				state.statusError = null;
			})
			.addCase(checkStatusThunk.fulfilled, (state, action) => {
				state.statusLoader = false;
				state.courseStatus = action.payload;
				state.statusError = null;
			})
			.addCase(checkStatusThunk.rejected, (state, action) => {
				state.statusLoader = false;
				state.statusError = action.error.message;
				state.courseStatus = null;
			});

		// Fetch course details thunk
		builder
			.addCase(fetchCourseDetailsThunk.pending, (state) => {
				state.loading = true;
				state.courseData = null;
				state.error["fetchCourseDetails"] = null;
			})
			.addCase(fetchCourseDetailsThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.courseData = action.payload;
				state.error["fetchCourseDetails"] = null;
			})
			.addCase(fetchCourseDetailsThunk.rejected, (state, action) => {
				state.loading = false;
				state.error["fetchCourseDetails"] = action.error.message;
				state.courseData = null;
			});
	},
});

export const { addCourseData, addModuleData, updateNodeData } = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
