import { createAsyncThunk } from "@reduxjs/toolkit";
import { addManualMaterial, deleteMaterial, fetchMaterialByNode, updateMaterial } from "../services/materialApiService";

export const addManualMaterialThunk = createAsyncThunk("material/addManualMaterial", async (material, { rejectWithValue }) => {
	try {
		const response = await addManualMaterial(material);
		return response;
	} catch (error) {
		rejectWithValue(error.message || "An error occurred while adding the material.");
	}
});

export const fetchMaterialByNodeThunk = createAsyncThunk("material/fetchMaterialByNode", async (nodeId, { rejectWithValue }) => {
	try {
		const response = await fetchMaterialByNode(nodeId);
		return response;
	} catch (error) {
		rejectWithValue(error.message || "An error occurred while fetching the material.");
	}
});

export const deleteMaterialThunk = createAsyncThunk("material/deleteMaterial", async ({ material_id, node_id }, { rejectWithValue }) => {
	try {
		const response = await deleteMaterial(material_id, node_id);
		return response;
	} catch (error) {
		rejectWithValue(error.message || "An error occurred while deleting the material.");
	}
});

export const updateMaterialThunk = createAsyncThunk("material/updateMaterial", async (material, { rejectWithValue }) => {
	try {
		const response = await updateMaterial(material);
		return response;
	} catch (error) {
		rejectWithValue(error.message || "An error occurred while updating the material.");
	}
});
