import React, { useState } from "react";
import "./AddStudent.css";
import "../../Common/Common.css";
import { Input } from "@mui/joy";
import ExcelReader from "../ExcelReader/ExcelReader";
import { API_URL } from "../../Config";
import axios from "axios";

const AddStudent = () => {
	const [studentData, setStudentData] = useState([]);

	const addStudentToAPI = async (data) => {
		try {
			for (const item of data) {
				if (item.email && item.student_name) {
					const student = await axios.post(`${API_URL}/student/signup`, {
						name: item.student_name,
						email: item.email,
						mobile_number: item.mobile_number ? item.mobile_number : null,
					});
				}
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	return (
		<div className='add-course-container'>
			<div className='header'>
				<span>Add Students</span>
				<div className='button-container'>
					<button>Save As Draft</button>
					<button>Publish</button>
				</div>
			</div>
			<div className=''>
				<div className='content'></div>
				<ExcelReader
					setData={setStudentData}
					addDataToAPI={addStudentToAPI}
				/>
				<button>s Upload</button>
			</div>
		</div>
	);
};

export default AddStudent;
