import { Box, Button, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import React, { useState } from "react";
import CsvParser from "./CsvParser";
import PreviewTable from "./PreviewTable";
import { Link } from "react-router-dom";

const CsvFileUpload = ({ openUploadPopup, closePopup, uploadClick, uploading, setUploading }) => {
	const [data, setData] = useState();
	const [error, setError] = useState();

	const handleClose = () => {
		closePopup();
		setData(null);
		setError(null);
		setUploading(false);
	};
	return (
		<Modal
			open={openUploadPopup}
			onClose={handleClose}
		>
			<ModalDialog>
				<Typography level='h4'>Upload Spreadsheet of Students</Typography>
				<ModalClose />
				<Box sx={{ display: "flex", flexDirection: "column", justifyItems: "center", gap: ".5rem" }}>
					<Typography level='body-md'>If you have many students to add, you can upload a spreadsheet with student details, as shown in the template below, to add them to Batch.</Typography>
					<Box sx={{ backgroundColor: "#f4f4f4", borderRadius: "10px", my: 2 }}>
						<Link
							style={{ textDecoration: "none" }}
							to={`${process.env.PUBLIC_URL}/sample_student_template.csv`}
							target='_blank'
							download={`sample_student_template.csv`}
						>
							<Typography sx={{ my: 1, textAlign: "center", justifySelf: "flex-end", color: "#3838F1", fontSize: "1rem", textDecoration: "none", cursor: "pointer" }}>
								Download Student info template
							</Typography>
						</Link>
					</Box>
					<CsvParser
						setData={setData}
						mandatoryFields={["name", "mobile_number", "email"]}
						setError={setError}
					/>
					{error && (
						<Typography
							level='p'
							sx={{ color: "red", opacity: ".5" }}
						>
							{error}
						</Typography>
					)}
				</Box>

				{data && (
					<div>
						<p className='mt-2 text-sm'>Records: {data.length}</p>
						<PreviewTable jsonData={data?.slice(0, 5)} />
					</div>
				)}
				<Box
					sx={{
						mt: 1,
						display: "flex",
						gap: 1,
						flexDirection: { xs: "column", sm: "row-reverse" },
					}}
				>
					<Button
						variant='solid'
						color='primary'
						disabled={uploading || !data}
						loading={uploading}
						onClick={() => uploadClick(data)}
					>
						Upload
					</Button>
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default CsvFileUpload;
