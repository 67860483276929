import React, { useEffect, useState } from "react";
import "./batch-list.css";
import "../../../Common/Common.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteBatchThunk, fetchBatchesThunk } from "../../../Store/actions/batchAction";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";
import { convertToIst } from "../../../helpers/dateTime";
import { useNavigate } from "react-router-dom";
import { clearAll, deleteBatchFromBatches } from "../../../Store/slices/batchSlice";
import { UserRole } from "../../../Constants/user-role";
import WithAuth from "../../utils/WithAuth";
import Loader from "../../utils/Loader";
import { Box, Button, Dropdown, Input, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Option, Select, Typography } from "@mui/joy";
import useDebounce from "../../../hooks/use-debounce";
import PaginationComponent from "../../utils/Pagination";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getFirstLetterCapitalized } from "../../../helpers/text-formatter";
import { DateTime } from "luxon";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const Batches = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { batches, error, loading } = useSelector((state) => state.batch);
	const { user } = useSelector((state) => state.user);

	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search, 500);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedBatchId, setSelectedBatchId] = useState(null);
	const [deleteLoader, setDeleteLoader] = useState(false);

	useEffect(() => {
		dispatch(clearAll());
	}, []);

	useEffect(() => {
		if (debouncedValue) {
			if (currentPage !== 1) setCurrentPage(1);
			else {
				dispatch(fetchBatchesThunk({ search: debouncedValue, page: currentPage }));
			}
		} else {
			setCurrentPage(1);
			dispatch(fetchBatchesThunk({ page: 1 }));
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (debouncedValue) {
			dispatch(fetchBatchesThunk({ search: debouncedValue, page: currentPage }));
		} else {
			dispatch(fetchBatchesThunk({ page: currentPage }));
		}
	}, [currentPage]);

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearch(value);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	if (error) {
		dispatch(showToast({ toastMessage: error, toastType: ToastType.ERROR }));
	}

	const handleDeleteBatch = async (batchId) => {
		setDeleteLoader(true);
		const response = await dispatch(deleteBatchThunk(batchId));
		if (response?.payload?.message) {
			setDeleteLoader(false);
			setShowDeleteModal(false);
			dispatch(deleteBatchFromBatches(batchId));
		}
	};

	const renderBatchDeleteModal = () => {
		return (
			<Modal
				open={showDeleteModal}
				onClose={(e) => {
					e.stopPropagation();
					setShowDeleteModal(false);
				}}
			>
				<ModalDialog onClick={(e) => e.stopPropagation()}>
					<h3>Are you sure you want to delete this batch?</h3>
					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<Button
							onClick={(event) => {
								event.stopPropagation();
								setShowDeleteModal(false);
							}}
							color='primary'
						>
							No
						</Button>
						<Button
							onClick={(event) => {
								event.stopPropagation();
								handleDeleteBatch(selectedBatchId);
							}}
							variant='outlined'
							color='danger'
							loading={deleteLoader}
						>
							Yes
						</Button>
					</div>
					<ModalClose onClick={(event) => event.stopPropagation()} />
				</ModalDialog>
			</Modal>
		);
	};

	const renderBatchCard = (batch) => {
		const startTime = DateTime.fromISO(batch.start_date).toFormat("dd LLL");
		const endTime = DateTime.fromISO(batch.end_date).toFormat("dd LLL ''yy");
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					p: ".8rem 1rem",
					bgcolor: "white",
					cursor: "pointer",
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				onClick={() => {
					navigate(`${batch.batch_id}`);
				}}
			>
				<Box>
					<Typography
						sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
						noWrap
					>
						{getFirstLetterCapitalized(batch?.name) || "Batch"}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Box>
						<Typography
							sx={{
								fontSize: ".8rem",
							}}
						>
							{startTime} - {endTime}
						</Typography>
					</Box>
					<Box>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
								mx: 2,
							}}
						>
							<Typography
								sx={{
									color: batch?.publish_status === "PUBLISHED" ? "#42B25B" : "#AEAED4",
									fontWeight: 600,
									fontSize: ".9rem",
								}}
							>
								{batch?.publish_status === "PUBLISHED" ? "Active" : "Draft"}
							</Typography>
						</Box>
					</Box>
					{(user?.role === UserRole.ADMIN || user?.role === UserRole.TENANT) && (
						<Box>
							<Dropdown>
								<MenuButton
									sx={{
										backgroundColor: "transparent",
										border: "none",
										cursor: "pointer",
										padding: 0,
									}}
									onClick={(e) => {
										e.stopPropagation();
									}}
								>
									<MoreHorizIcon />
								</MenuButton>
								<Menu
									modifiers={[
										{
											name: "offset",
											options: {
												offset: ({ placement }) => {
													if (placement.includes("end")) {
														return [8, 20];
													}
													return [50, -20];
												},
											},
										},
									]}
								>
									<MenuItem
										color='danger'
										onClick={(e) => {
											e.stopPropagation();
											setSelectedBatchId(batch.batch_id);
											setShowDeleteModal(true);
										}}
									>
										Delete
									</MenuItem>
								</Menu>
							</Dropdown>
						</Box>
					)}
				</Box>
				{showDeleteModal && selectedBatchId === batch.batch_id && renderBatchDeleteModal()}
			</Box>
		);
	};

	const renderBatches = () => {
		if (loading)
			return (
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
					<Loader />
				</div>
			);
		else if (batches?.data?.data?.length > 0) {
			// dispatch(showToast({ toastMessage: "Batches fetched successfully", toastType: ToastType.SUCCESS }));
			return (
				<Box
					sx={{
						maxHeight: "70vh",
						overflowY: "auto",
						bgcolor: "white",
						borderRadius: "8px",
					}}
				>
					{batches?.data?.data?.map((batch, i) => renderBatchCard(batch))}
				</Box>
			);
		} else {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "50vh",
					}}
				>
					No Batches available
				</div>
			);
		}
	};

	const renderPagination = () => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: "2rem",
				}}
			>
				<Box
					sx={{
						display: "flex",
						bgcolor: "white",
						justifyContent: "center",
						alignItems: "center",
						padding: " .5rem",
						borderRadius: "8px",
						gap: 5,
					}}
				>
					<PaginationComponent
						totalPage={batches?.data?.totalPages}
						onPageChange={handlePageChange}
						currentPage={currentPage}
					/>
				</Box>
			</Box>
		);
	};

	return (
		<div className='batches-list-container'>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: "1.5rem",
				}}
			>
				<Typography sx={{ marginRight: "0.2rem", fontWeight: 600, fontSize: "1.5rem" }}>Batches &nbsp;{batches?.data?.count}</Typography>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 3,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							border: "1px solid rgba(0, 0, 0, 0.1)",
							borderRadius: "8px",

							padding: ".1rem .5rem",
						}}
					>
						<SearchIcon
							sx={{
								cursor: "pointer",
							}}
						/>
						<Input
							placeholder='Search Batch'
							value={search}
							sx={{
								bgcolor: "transparent",
								boxShadow: "none",
								border: "none",
								outline: "none",
								"--Input-focusedThickness": "0rem",
							}}
							onChange={handleSearchChange}
						/>
					</Box>

					{(user?.role === UserRole.ADMIN || user?.role === UserRole.TENANT) && (
						<Box sx={{ display: "flex", gap: 10 }}>
							<Button
								variant='primary'
								sx={{ bgcolor: "#3838F1" }}
								onClick={() => navigate("addNewBatch")}
							>
								<Typography sx={{ width: "fit-content", color: "white" }}>Create New Batch</Typography>
							</Button>
						</Box>
					)}
				</Box>
			</Box>
			{renderBatches()}
			{renderPagination()}
		</div>
	);
};

export default WithAuth(Batches);
