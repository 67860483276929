import { createSlice, current } from "@reduxjs/toolkit";
import { fetchEventsByBatchIdThunk } from "../actions/batchEventAction";

const initialState = {
	events: [],
	loading: false,
	error: null,
};

const batchEventSlice = createSlice({
	name: "batchEvent",
	initialState,
	reducers: {
		addEvent: (state, action) => {
			let currentState = current(state);
			state.events = [...currentState.events, action.payload];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEventsByBatchIdThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchEventsByBatchIdThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.events = action.payload?.data;
			})
			.addCase(fetchEventsByBatchIdThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { addEvent } = batchEventSlice.actions;

export default batchEventSlice.reducer;
