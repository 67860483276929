import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	toastMessage: "",
	toastType: "",
	toastOpen: false,
	currentTab: "course",
};

const commonSlice = createSlice({
	name: "toast",
	initialState,
	reducers: {
		showToast: (state, action) => {
			state.toastMessage = action.payload.toastMessage;
			state.toastType = action.payload.toastType;
			state.toastOpen = true;
		},
		hideToast: (state) => {
			state.toastOpen = false;
			state.toastMessage = "";
			state.toastType = "";
		},
		setCurrentTab: (state, action) => {
			state.currentTab = action.payload;
		},
	},
});

export const { showToast, hideToast, setCurrentTab } = commonSlice.actions;
export default commonSlice.reducer;
