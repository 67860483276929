import axios from "axios";
import { API_URL } from "../../Config";

const fetchToken = () => {
	return localStorage.getItem("token");
};

export const fetchAllTenants = async () => {
	const response = await axios.get(`${API_URL}/tenant/admin-tenants`, {
		headers: {
			Authorization: `Bearer ${fetchToken()}`,
		},
	});
	return response.data;
};

export const getCoursesByTenantId = async (tenantId) => {
	const response = await axios.get(`${API_URL}/tenant/courses?tenant_id=${tenantId}`);
	return response.data;
};
