import React, { useState } from "react";
import { Box, Input, RadioGroup, FormControlLabel, Radio, Button, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { Input } from "@mui/joy"
import { useDispatch } from "react-redux";
import { createEventForBatchThunk } from "../../../Store/actions/batchEventAction";
import { DateTime } from "luxon";
import { addEvent } from "../../../Store/slices/batchEventSlice";

const AddNewEvent = ({ batch_id, setOpen }) => {
	const [eventType, setEventType] = useState("MEET");
	const [eventLink, setEventLink] = useState("");
	const [eventName, setEventName] = useState("");
	const [instructorName, setInstructorName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [imageFile, setImageFile] = useState(null);

	const dispatch = useDispatch();

	const getMeetingNameFromLink = (link) => {
		return link.includes("zoom") ? "ZOOM" : link.includes("meet.google") ? "GOOGLE_MEET" : link.includes("teams.microsoft") ? "Microsoft Teams" : "Other";
	};

	const toBase64 = async (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleSubmit = async () => {
		if (!eventName || !eventLink || !instructorName || !startDate || !endDate) return;
		let image;
		const start_date = DateTime.fromISO(`${startDate}T${startTime}`).toUTC().toISO();
		const end_date = DateTime.fromISO(`${endDate}T${endTime}`).toUTC().toISO();
		const meetingType = getMeetingNameFromLink(eventLink);
		let eventData;
		if (imageFile) {
			eventData = new FormData();
			eventData.append("event-image", imageFile);
			eventData.append("name", eventName);
			eventData.append("meeting_url", eventLink);
			eventData.append("instructor_name", instructorName);
			eventData.append("start_time", start_date);
			eventData.append("end_time", end_date);
			eventData.append("meeting_type", meetingType);
			eventData.append("batch_event_type", eventType);
			eventData.append("batch_id", batch_id);
		} else {
			eventData = {
				name: eventName,
				meeting_url: eventLink,
				instructor_name: instructorName,
				start_time: start_date,
				end_time: end_date,
				meeting_type: meetingType,
				batch_event_type: eventType,
				batch_id,
			};
		}
		const response = await dispatch(createEventForBatchThunk(eventData));

		if (response?.payload?.data) {
			dispatch(
				addEvent({
					name: eventName,
					meeting_url: eventLink,
					instructor_name: instructorName,
					start_time: start_date,
					end_time: end_date,
					meeting_type: meetingType,
					batch_event_type: eventType,
					batch_id,
					event_image: response?.payload?.data[0]?.event_image,
				})
			);
			setOpen(false);
		}
	};

	return (
		<Box>
			<Box sx={{ px: 2, maxHeight: "70vh", overflowY: "auto" }}>
				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Add Event Name
				</Typography>

				<Input
					fullWidth
					label='Event Name'
					placeholder='Clarification Call'
					onChange={(e) => setEventName(e.target.value)}
					variant='outlined'
					sx={{ mb: 2 }}
				/>

				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Event Type
				</Typography>
				<RadioGroup row>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1, mb: 2 }}>
						<Box sx={{ width: "100%" }}>
							<FormControlLabel
								value={eventType}
								control={<Radio />}
								disabled={true}
								label={
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											padding: 2,
											border: "1px solid",
											borderColor: "divider",
											borderRadius: 1, // Adjust width as needed
											textAlign: "center",
											"&:hover": {
												backgroundColor: "action.hover",
											},
										}}
									>
										<Typography variant='body1'>Offline</Typography>
										<Typography
											variant='caption'
											sx={{ width: "fit-content" }}
										>
											For events that are typically conducted at a location and in-person.
										</Typography>
									</Box>
								}
							/>
						</Box>
						<Box sx={{ width: "100%" }}>
							<FormControlLabel
								value={eventType}
								control={<Radio />}
								checked={true}
								label={
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											padding: 2,
											border: "1px solid",
											borderColor: "divider",
											borderRadius: 1,
											"&.Mui-checked": {
												backgroundColor: "action.selected",
											},
											textAlign: "center",
											"&:hover": {
												backgroundColor: "action.hover",
											},
										}}
									>
										<Typography variant='body1'>Virtual</Typography>
										<Typography
											variant='caption'
											sx={{ width: "fit-content" }}
										>
											For events or sessions that are conducted online and require meet/call link.
										</Typography>
									</Box>
								}
							/>
						</Box>
					</Box>
				</RadioGroup>
				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Meeting link
				</Typography>
				<Input
					fullWidth
					label='Meet/Call Link'
					placeholder='https://zoom.us/234q3562w6'
					variant='outlined'
					onChange={(e) => setEventLink(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<Typography
					variant='body1'
					sx={{ mb: 1 }}
				>
					Instructor Name
				</Typography>

				<Input
					fullWidth
					label='Instructor Name'
					placeholder='Prof. Sushant Sinha'
					variant='outlined'
					onChange={(e) => setInstructorName(e.target.value)}
					sx={{ mb: 2 }}
				/>

				<Box sx={{ display: "flex", gap: 2, mb: 2 }}>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							Start Date
						</Typography>
						<Input
							fullWidth
							type='date'
							label='Start Date'
							placeholder='10 Jun 2024'
							variant='outlined'
							onChange={(e) => setStartDate(e.target.value)}
						/>
					</Box>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							Time
						</Typography>
						<Input
							type='time'
							fullWidth
							label='Time'
							placeholder='Choose'
							variant='outlined'
							onChange={(e) => setStartTime(e.target.value)}
						/>
					</Box>
				</Box>

				<Box sx={{ display: "flex", gap: 2 }}>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							End Date
						</Typography>
						<Input
							fullWidth
							type='date'
							label='Start Date'
							placeholder='10 Jun 2024'
							variant='outlined'
							slotProps={{
								input: {
									min: startDate,
								},
							}}
							onChange={(e) => setEndDate(e.target.value)}
						/>
					</Box>
					<Box sx={{ width: "50%" }}>
						<Typography
							variant='body1'
							sx={{ mb: 1 }}
						>
							Time
						</Typography>
						<Input
							type='time'
							fullWidth
							label='Time'
							placeholder='Choose'
							variant='outlined'
							onChange={(e) => setEndTime(e.target.value)}
						/>
					</Box>
				</Box>
				<Box sx={{ my: 1 }}>
					<Typography
						variant='body1'
						sx={{ mb: 1 }}
					>
						Add Image
					</Typography>
					<Input
						type='file'
						slotProps={{
							input: {
								accept: "image/*",
							},
						}}
						onChange={(e) => setImageFile(e.target.files[0])}
					/>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
				<Button
					variant='outlined'
					sx={{ mr: 2 }}
				>
					Cancel
				</Button>
				<Button
					variant='contained'
					color='primary'
					disabled={!eventName || !eventLink || !instructorName || !startDate || !endDate || !startTime || !endTime}
					onClick={handleSubmit}
				>
					Create Event
				</Button>
			</Box>
		</Box>
	);
};

export default AddNewEvent;
